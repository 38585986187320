import React, {Fragment, useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

// Types
import {UserSession} from '@compt/types/account';

// Components
import {Menu, Transition} from '@headlessui/react';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {UserAccountSettingsForm} from './user-account-settings-form';
import {ChangePasswordForm} from './change-password-form';
import {ComptInitialsAvatar} from '../compt-initials-avatar/compt-initials-avatar';
import comptColors from '@compt/styles/compt-colors';

interface UserMenuProps {
  userDataState: UserSession;
}

export const UserMenu = (props: UserMenuProps) => {
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openUserAccountSettings, setOpenUserAccountSettings] = useState(false);
  const [verificationToken, setVerificationToken] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('verifyAdditionalEmail');

    if (token) {
      searchParams.delete('verifyAdditionalEmail');
      setSearchParams(searchParams);
      setVerificationToken(token);
      setOpenUserAccountSettings(true);
    }
  }, [searchParams, setSearchParams]);

  return (
    <div>
      <UserAccountSettingsForm
        open={openUserAccountSettings}
        setOpen={setOpenUserAccountSettings}
        verificationToken={verificationToken}
        clearVerificationToken={() => setVerificationToken(null)}
      />
      <ChangePasswordForm open={openChangePassword} setOpen={setOpenChangePassword} />

      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-25 px-4 py-2.5
    text-sm font-semibold text-color-body1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Hello, {props.userDataState.nickname}!
            <ComptSvgIcon iconName="chevron-down-icon" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={
              'absolute right-0 z-10 mt-2 w-full min-w-max origin-top-right divide-y divide-gray-100 ' +
              'rounded-md bg-gray-25 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
            }
          >
            <div className="py-1">
              <Menu.Item>
                <div className="flex px-300 py-200 items-center">
                  <ComptInitialsAvatar className="w-[40px] h-[40px] border-[1px]">
                    <p className="body2 py-100 px-150" style={{color: comptColors.brand[700]}}>
                      {props.userDataState.first_name[0]}
                      {props.userDataState.last_name[0]}
                    </p>
                  </ComptInitialsAvatar>
                  <div className="ml-3">
                    <p className="label3">
                      {props.userDataState.first_name} {props.userDataState.last_name}
                    </p>
                    <p className="body3 text-color-body2">{props.userDataState.email}</p>
                  </div>
                </div>
              </Menu.Item>
            </div>
            <div className="py-1">
              <p className="heading6 px-300 pt-100 text-color-tertiary uppercase">Profile</p>
              <Menu.Item>
                <ComptButton
                  buttonType={ComptButtonType.BORDERLESS}
                  onClick={() => setOpenUserAccountSettings(true)}
                  iconId="user-icon"
                >
                  <p className="body3 compt-text--medium ml-2">My account</p>
                </ComptButton>
              </Menu.Item>
              <Menu.Item>
                <ComptButton
                  buttonType={ComptButtonType.BORDERLESS}
                  onClick={() => setOpenChangePassword(true)}
                  iconId="password-lock-icon"
                >
                  <p className="body3 compt-text--medium ml-2">Change password</p>
                </ComptButton>
              </Menu.Item>
            </div>
            <div className="py-1">
              <p className="heading6 px-300 pt-100 text-color-tertiary uppercase">Legal</p>
              <Menu.Item>
                <a href="/terms">
                  <ComptButton buttonType={ComptButtonType.BORDERLESS} iconId="file-icon">
                    <p className="body3 compt-text--medium ml-2">Terms of use</p>
                  </ComptButton>
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href="https://www.compt.io/compt-privacy-policy">
                  <ComptButton buttonType={ComptButtonType.BORDERLESS} iconId="file-lock-icon">
                    <p className="body3 compt-text--medium ml-2">Privacy policy</p>
                  </ComptButton>
                </a>
              </Menu.Item>
              <Menu.Item>
                <ComptButton
                  buttonType={ComptButtonType.BORDERLESS}
                  iconId="settings-slider-icon"
                  onClick={window.displayPreferenceModal}
                >
                  <p className="body3 compt-text--medium ml-2">Cookie preferences</p>
                </ComptButton>
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item>
                <a href="/accounts/logout">
                  <ComptButton
                    buttonType={ComptButtonType.BORDERLESS}
                    iconId={ComptButtonIcon.LOG_OUT}
                  >
                    <p className="body3 compt-text--medium ml-2">Log out</p>
                  </ComptButton>
                </a>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
