import React, {useState} from 'react';

// RTK Queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useCreateBusinessExpenseReportMutation} from '@compt/app/services/api/business-expense-reports-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';

// Hooks and Methods
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {useNavigate} from 'react-router-dom';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Types
import {FieldValues} from 'react-hook-form';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';

// Components
import {BusinessExpenseReportsTable} from '@compt/pages/business-expenses/business-expense-reports-page/business-expense-reports-table.container';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {BusinessReportSidePanel} from '../components/business-report-side-panel';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {ComptTableContextProvider} from '@compt/common/compt-table/compt-table.context';
import {BusinessExpensePolicyModal} from '../components/business-expense-policy-modal';
import {ExpensePolicyButton} from './components/business-expense-policy-button';

export const BusinessExpenseReportsPage = () => {
  const [openCreateSidePanel, setOpenCreateSidePanel] = useState(false);
  const navigate = useNavigate();

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const company = companyQuery.data;
  const [openPolicyModal, setOpenPolicyModal] = useState(false);

  const isMobileView = useIsMobileView();

  const [createExpenseReport, {isLoading}] = useCreateBusinessExpenseReportMutation();
  const hasRequiredRole = session.data?.roles.includes(USER_ROLES.businessExpenseEligible);

  if (!hasRequiredRole) {
    return <Error404Page />;
  }

  const onCreateReportSubmit = (form: FieldValues) => {
    const submission: Partial<BusinessExpenseReport> = {
      title: form.title,
      description: form.description,
    };

    createExpenseReport(submission).then((body) => {
      if ('data' in body) {
        const reportId = body.data.report.id;
        navigate(`/business-expenses/${reportId}`);
        triggerCustomToast('success', 'Successfully created report');
      }
    });
  };

  if (!company) return null;

  return (
    <ComptTableContextProvider>
      <BusinessReportSidePanel
        open={openCreateSidePanel}
        setOpen={setOpenCreateSidePanel}
        onSubmit={onCreateReportSubmit}
        mutationLoading={isLoading}
      />
      <BusinessExpensePolicyModal
        open={openPolicyModal}
        setOpen={setOpenPolicyModal}
        company={company}
      />
      <ComptPage
        title="Business Expenses"
        subtitle="View or submit expense reports"
        className="h-dvh pb-500 sm:pb-000"
        includeBottomHR={false}
        action={
          session.isSuccess &&
          !isMobileView && (
            <div className="flex gap-2">
              <ExpensePolicyButton
                company={company}
                setOpenPolicyModal={setOpenPolicyModal}
                isMobileView={false}
              />
              <ComptButton
                className="sm:w-auto w-3/5 mb-2"
                iconId={ComptButtonIcon.PLUS}
                onClick={() => setOpenCreateSidePanel(true)}
              >
                New expense report
              </ComptButton>
            </div>
          )
        }
      >
        <BusinessExpenseReportsTable />
      </ComptPage>
      {isMobileView && (
        <div
          className={`fixed bottom-0 bg-white left-1/2 transform z-10
          -translate-x-1/2 w-full p-300 border-t border-t-gray-300`}
        >
          <ExpensePolicyButton
            company={company}
            setOpenPolicyModal={setOpenPolicyModal}
            isMobileView={true}
          />
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            iconId={ComptButtonIcon.PLUS}
            data-testid="new-expense-report-mobile-button"
            className="w-full"
            onClick={() => setOpenCreateSidePanel(true)}
          >
            New expense report
          </ComptButton>
        </div>
      )}
    </ComptTableContextProvider>
  );
};
