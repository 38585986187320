import React, {useContext, useEffect, useState} from 'react';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {BusinessExpenseTableController} from './business-expense-table.controller';
import {
  BusinessExpense,
  BusinessExpenseWithError,
} from '@compt/types/business-expenses/business-expense';
import {DeleteBusinessExpenseModal} from '../delete-business-expense-modal';
import {MAX_BUSINESS_EXPENSES_PER_PAGE} from '@compt/constants';
import {Company} from '@compt/types/company';
import {ExistingBusinessExpenseSidePanel} from '../existing-business-expense-side-panel/existing-business-expense-side-panel';
import {BusinessExpensePageContext} from '../../business-expense-page.context';

interface BusinessExpenseTableProps {
  expenseListResults: BusinessExpense[];
  company: Company;
}

export const BusinessExpenseTable = (props: BusinessExpenseTableProps) => {
  const {expenseListResults, company} = props;
  const controller = new BusinessExpenseTableController();
  const {errorRows} = useContext(BusinessExpensePageContext);

  const [selectedExpense, setSelectedExpense] = useState<BusinessExpense | null>(null);
  const [expenseToDelete, setExpenseToDelete] = useState<BusinessExpense | null>(null);
  const [editMode, setEditMode] = useState(false);

  const [openExpenseSidePanel, setOpenExpenseSidePanel] = useState(false);

  const [adjustedExpenseList, setAdjustedExpenseList] = useState<
    BusinessExpenseWithError[] | BusinessExpense[]
  >([]);

  const onActionMenuClicked = (expense: BusinessExpense, action: 'VIEW' | 'EDIT' | 'DELETE') => {
    switch (action) {
      case 'VIEW':
        setSelectedExpense(expense);
        setOpenExpenseSidePanel(true);
        setEditMode(false);
        break;
      case 'EDIT':
        setSelectedExpense(expense);
        setOpenExpenseSidePanel(true);
        setEditMode(true);
        break;
      case 'DELETE':
        setExpenseToDelete(expense);
        break;
    }
  };

  const columnDefinition = controller.getColumnDefinition(onActionMenuClicked);

  const conditionalRowStyles = [
    {
      when: (expense: BusinessExpenseWithError | BusinessExpense) =>
        'error' in expense && !!expense.error,
      style: {
        backgroundColor: '#f9dadb',
        color: 'black',
      },
    },
  ];

  // Set errors in table rows if they exist
  useEffect(() => {
    if (!expenseListResults) return;

    if (expenseListResults.length === 0) {
      setAdjustedExpenseList([]);
      return;
    }

    if (!errorRows) {
      setAdjustedExpenseList(expenseListResults);
      return;
    }

    const expenseListWithErrors = expenseListResults.map((expense) => {
      const expenseHasError = errorRows?.[expense.id];
      if (expenseHasError) {
        return {...expense, error: true};
      }

      return {...expense, error: false};
    });

    setAdjustedExpenseList(expenseListWithErrors);
  }, [errorRows, expenseListResults]);

  return (
    <>
      <ExistingBusinessExpenseSidePanel
        open={openExpenseSidePanel}
        selectedExpense={selectedExpense}
        expenseListResults={adjustedExpenseList}
        setSelectedExpense={setSelectedExpense}
        setExpenseToDelete={setExpenseToDelete}
        setOpen={setOpenExpenseSidePanel}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      <DeleteBusinessExpenseModal
        expenseToDelete={expenseToDelete}
        setExpenseToDelete={setExpenseToDelete}
        setOpenExpenseSidePanel={setOpenExpenseSidePanel}
      />
      <ComptTable
        company={company}
        tableId="business-expense-table-v2.0.0"
        className="w-full"
        data={adjustedExpenseList}
        dataLoading={false}
        noDataTitleText="No expenses have been added yet"
        noDataSubtitle={
          <p className="body1 text-center">
            You haven&apos;t added any expenses yet. Click on &quot;Add Expense&quot; to get
            started!
          </p>
        }
        allowShowHide={false}
        columnDefinition={columnDefinition}
        allowPagination={false}
        totalCount={adjustedExpenseList.length}
        itemsPerPage={MAX_BUSINESS_EXPENSES_PER_PAGE}
        conditionalRowStyles={conditionalRowStyles}
        onRowClicked={(row) => {
          setSelectedExpense(row);
          setOpenExpenseSidePanel(true);
        }}
        stickyLastColumn
      />
    </>
  );
};
