import {FunctionComponent} from 'react';

// RTK queries
import {
  useGetRestitutionOptionQuery,
  useRejectExpenseReviewMutation,
} from '@compt/app/services/api/admin-stipends-slice';
import {useGetBundledQueries} from '@compt/utils/queries-helper';

// Hooks and methods
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {
  formatCurrencyFromCountryCode,
  formatCurrencySymbolFromCountryCode,
  SupportedCountriesType,
} from '@compt/utils/international-helpers';
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {useFormatFilterQuery, usePaginationQuery} from '../../format-query-helper';

// Types
import {DEFAULT_CHAR_FIELD_MAX_LENGTH, MAX_50_PAGE_LIMIT} from '@compt/constants';
import {ExpenseReview} from '@compt/types/stipend-expenses/expense-review';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

// Components
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptRadioField} from '@compt/common/forms/compt-radio-field/compt-radio-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';

interface RejectionFormValues {
  rejection_reason: string;
  refund_option: string;
  allotment_id: number;
}

interface Props {
  rejectionOpen: boolean;
  setRejectionOpen: (value: boolean) => void;
  expense: ExpenseReview | undefined;
  RequestStatusPill: FunctionComponent<{expense: ExpenseReview | undefined}>;
}

export const ReviewStipendRejectionSidePanel = (props: Props) => {
  const {RequestStatusPill} = props;
  const [, setSearchParams] = useSearchParams();

  const formattedFilterQuery = useFormatFilterQuery();
  const paginationQuery = usePaginationQuery(MAX_50_PAGE_LIMIT);

  const {session, company} = useGetBundledQueries();
  const restitutionQuery = useGetRestitutionOptionQuery(
    {
      companyId: company.data?.id,
      expenseId: props.expense?.id,
    },
    {skip: !props.expense?.id || !company.data?.id},
  );

  const [rejectExpenseReview] = useRejectExpenseReviewMutation();

  const formMethods = useForm<RejectionFormValues>();

  function resetForm() {
    formMethods.reset({rejection_reason: '', refund_option: ''});
  }

  function onSubmitReject(form: RejectionFormValues) {
    if (!props.expense) return;

    const body = {
      rejection_reason: form.rejection_reason,
      refund_option: form.refund_option,
      allotment_id: props.expense.allotments[0].id,
      forward_cycle_id: restitutionQuery.data?.forward_cycle_id,
    };

    rejectExpenseReview({
      companyId: company.data?.id,
      expenseId: props.expense.id,
      body,
      filters: {
        ...formattedFilterQuery,
        ...paginationQuery,
        limit: MAX_50_PAGE_LIMIT,
        ordering: 'created_on',
      },
    }).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem rejecting this expense',
          'Please try again.',
        );
        return;
      }

      if (!props.expense) return;

      triggerCustomToast('success', 'Successfully rejected this expense');
      resetForm();
      setSearchParams({expense_id: props.expense?.id.toString()});
      props.setRejectionOpen(false);
    });
  }

  return (
    <ComptSidePanel className="max-w-[581px]" open={props.rejectionOpen}>
      <ComptSidePanel.Header
        title="Review submission"
        setOpen={() => {
          resetForm();
          props.setRejectionOpen(false);
        }}
        headerIcon={{id: 'file-icon-blue'}}
        statusPill={<RequestStatusPill expense={props.expense} />}
        breadCrumbs={['Review expense', 'Reject expense']}
      />
      <ComptLoadingIndicator isLoading={restitutionQuery.isLoading || restitutionQuery.isFetching}>
        <ComptSidePanel.Content className="overflow-y-scroll p-6">
          <div className="flex flex-col mb-6">
            <p className="body2">Submitted on</p>
            <p className="body1 mt-050">
              {formattedDate(props.expense?.created_on, DATE_FORMAT_OPTION['month dd yyyy'])}
            </p>
          </div>
          <ComptTextField
            name="full_name"
            label="Employee name"
            initialValue={props.expense?.full_name}
            readOnly
            disabled
          />
          <ComptTextField
            name="vendor"
            label="Vendor"
            initialValue={props.expense?.vendor_name}
            readOnly
            disabled
          />
          <ComptTextField
            name="amount_covered"
            label="Amount covered"
            initialValue={`${formatCurrencySymbolFromCountryCode(
              session?.data?.country,
              props.expense?.currency as SupportedCountriesType,
            )} ${props.expense?.amount_claimed}`}
            readOnly
            disabled
          />
          {props.expense?.amount_of_expense && (
            <p className="body3 text-color-body2 -mt-4 mb-6">
              User entered:{' '}
              {formatCurrencyFromCountryCode(
                props.expense?.amount_of_expense,
                session.data?.country,
                props.expense?.currency,
              )}
            </p>
          )}
          <ComptTextAreaField
            name="rejection_reason"
            label="Description and reason for rejection"
            control={formMethods.control}
            register={formMethods.register}
            validation={{required: 'Description and reason for rejection is required'}}
            errors={formMethods.formState.errors.rejection_reason}
            watchedValue={formMethods.watch('rejection_reason')}
            maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
          />
          <ComptRadioField
            label="Mode of rejection"
            validation={{
              required: 'Mode of rejection is required',
            }}
            options={getRefundOptions(
              !!restitutionQuery.data?.has_allotment_expiring_soon,
              restitutionQuery.data?.forward_date_date ?? '',
              formatCurrencyFromCountryCode(
                props.expense?.amount_claimed ?? '',
                session.data?.country,
                props.expense?.currency,
              ),
            )}
            id="refund_option"
            name="refund_option"
            register={formMethods.register}
            control={formMethods.control}
            errors={formMethods.formState.errors.refund_option}
          />
        </ComptSidePanel.Content>
        <ComptSidePanel.Footer>
          <div className="flex justify-between gap-x-3">
            <ComptButton
              buttonType={ComptButtonType.DESTRUCTIVE}
              onClick={formMethods.handleSubmit(onSubmitReject)}
            >
              Confirm rejection
            </ComptButton>
            <ComptButton
              buttonType={ComptButtonType.SECONDARY}
              onClick={() => {
                if (!props.expense) return;
                resetForm();
                props.setRejectionOpen(false);
                setSearchParams({expense_id: props.expense.id.toString()});
              }}
            >
              Cancel and go back
            </ComptButton>
          </div>
        </ComptSidePanel.Footer>
      </ComptLoadingIndicator>
    </ComptSidePanel>
  );
};

export enum REFUND_OPTION {
  NO_REFUND = 'NO_REFUND',
  REFUND_IMMEDIATELY = 'REFUND_IMMEDIATELY',
  REFUND_NEXT_CYCLE = 'REFUND_NEXT_CYCLE',
}

function getRefundOptions(
  expireSoon: boolean,
  forwardDate: string,
  amountCurrencyToReturn: string,
) {
  const refundOptions: {id: REFUND_OPTION; label: string; description?: string}[] = [
    {
      id: REFUND_OPTION.NO_REFUND,
      label: 'Reject and do not return funds to any stipend',
    },
  ];

  if (expireSoon) {
    refundOptions.push({
      id: REFUND_OPTION.REFUND_NEXT_CYCLE,
      label: `Refund on ${formattedDate(forwardDate, DATE_FORMAT_OPTION['month dd yyyy'])}`,
      description: `${amountCurrencyToReturn} will be returned to the
      employee at the start of the next stipend cycle.
      These funds will expire at the end of the next cycle,
      giving the employee more time to spend the funds.`,
    });
  } else {
    refundOptions.push({
      id: REFUND_OPTION.REFUND_IMMEDIATELY,
      label: 'Reject this claim and return funds to the employee immediately',
      description: `${amountCurrencyToReturn} will be returned to the employee immediately.`,
    });
  }

  return refundOptions;
}
