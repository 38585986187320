import {comptApiSlice, ComptRTKTags} from './api-slice';
import {
  DraftExpense,
  DraftExpenseListResults,
} from '@compt/pages/employee-stipends/activity-page/draft-expenses/draft-expense.types';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDraftExpenses: builder.query<DraftExpenseListResults, {filter?: Record<string, unknown>}>({
      query: ({filter}) => ({
        url: 'me/expense_drafts/',
        params: {
          ...filter,
        },
      }),
      providesTags: [ComptRTKTags.DRAFT_EXPENSE_LIST],
    }),
    getDraftExpense: builder.query<DraftExpense, {draftExpenseId: number}>({
      query: ({draftExpenseId}) => `/me/expense_drafts/${draftExpenseId}`,
      providesTags: (result, error, {draftExpenseId}) => [
        {type: ComptRTKTags.DRAFT_EXPENSE, id: draftExpenseId},
      ],
    }),
    deleteDraftExpense: builder.mutation<void, number>({
      query: (draftId) => ({
        url: `/me/expense_drafts/${draftId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, draftId) => [
        {type: ComptRTKTags.DRAFT_EXPENSE, id: draftId},
        ComptRTKTags.DRAFT_EXPENSE_LIST,
      ],
    }),
  }),
});

export const {useGetDraftExpensesQuery, useGetDraftExpenseQuery, useDeleteDraftExpenseMutation} =
  extendedComptApiSlice;
