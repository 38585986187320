import React, {useState} from 'react';

// Types
import {ExpenseReview} from '@compt/types/stipend-expenses/expense-review';
import {getStatusNumber, STATUS_KEYS} from '@compt/types/stipend-expenses/stipend-expenses';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';
import {ReviewStipendTableController as controller} from './stipend-expenses/components/review-stipend-table.controller';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

// Components
import {ReviewStipendTable} from './stipend-expenses/components/review-stipend-table.container';
import {
  ReviewStipendSidebar,
  StipendFilter,
} from './stipend-expenses/components/review-stipend-table-sidebar';
import {FilterContextProvider} from '@compt/common/compt-filter-sidebar/filter-context';
import {ComptPortal, PortalRoot} from '@compt/common/compt-portal/compt-portal';
import {ReviewScoreCardCarousel} from './components/review-score-card-carousel';
import {LeanComptTableContextProvider} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {FilterWarningBanner} from './components/filter-banner';

// TODO: Replace in COMPT-6351
export const dummyStipendsData = [
  {
    id: 1,
    title: 'Total spend',
    total_amount: 104302,
    remaining_amount: 60000,
    spent_amount: 44302,
  },
  {
    id: 2,
    title: 'Find your balance! | Spend',
    total_amount: 1500,
    remaining_amount: 600,
    spent_amount: 600,
  },
  {
    id: 3,
    title: "Here's to your health | Spend",
    total_amount: 1500,
    remaining_amount: 700,
    spent_amount: 500,
  },
  {
    id: 4,
    title: 'Always be learning | Spend',
    total_amount: 1500,
    remaining_amount: 800,
    spent_amount: 700,
  },
  {
    id: 5,
    title: 'Always be learning | Spend',
    total_amount: 1500,
    remaining_amount: 900,
    spent_amount: 700,
  },
  {
    id: 6,
    title: 'Always be learning | Spend',
    total_amount: 1500,
    remaining_amount: 1000,
    spent_amount: 700,
  },
  {
    id: 7,
    title: 'Always be learning | Spend',
    total_amount: 1500,
    remaining_amount: 1100,
    spent_amount: 700,
  },
  {
    id: 8,
    title: 'Always be learning | Spend',
    total_amount: 1500,
    remaining_amount: 1200,
    spent_amount: 700,
  },
];

export const ReviewStipendTab = () => {
  const FILTER_ID = 'review-stipend-table-v1.0.1';
  const initialFilterValues: Record<StipendFilter, string[] | string> = {
    [StipendFilter.COUNTRY]: [],
    [StipendFilter.STATUS]: [],
    [StipendFilter.HIDE_CLAIM_VERIFICATION]: '',
    [StipendFilter.ONLY_CLAIM_VERIFICATION]: '',
    [StipendFilter.HIDE_SCANNED]: '',
    [StipendFilter.ONLY_SCANNED]: '',
    [StipendFilter.STIPEND]: [],
    [StipendFilter.TAXABLE]: '',
    [StipendFilter.NON_TAXABLE]: '',
    [StipendFilter.CURRENCY]: [],
    [StipendFilter.CATEGORY]: [],
    [StipendFilter.RECEIPT_DATE_START]: '',
    [StipendFilter.RECEIPT_DATE_END]: '',
    [StipendFilter.AMOUNT_MIN]: '',
    [StipendFilter.AMOUNT_MAX]: '',
    [StipendFilter.DATE_SUBMITTED_START]: '',
    [StipendFilter.DATE_SUBMITTED_END]: '',
    [StipendFilter.SEARCH]: '',
    [StipendFilter.COLUMN_FILTER]: '',
  };

  const isMobileView = useIsMobileView();
  const [, setSearchParams] = useSearchParams();
  const [isEditingMode, setIsEditingMode] = useState(false);

  const columnDefinition = controller.getColumnDefinition(onActionMenuClicked);

  function onActionMenuClicked(
    expense: ExpenseReview,
    action: 'VIEW' | 'EDIT' | 'APPROVE' | 'REJECT',
  ) {
    switch (action) {
      case 'VIEW':
        setSearchParams({expense_id: `${expense.id}`});
        setIsEditingMode(false);
        break;
      case 'EDIT':
        setSearchParams({expense_id: `${expense.id}`});
        setIsEditingMode(true);
        break;
      case 'REJECT':
        break;
    }
  }

  const shouldShowCarousel =
    !isMobileView &&
    featureEnabled(FeatureFlags.CONSOLIDATE_PROGRAMS) &&
    featureEnabled(FeatureFlags.SHOW_REVIEW_SCORECARDS) &&
    dummyStipendsData.length > 0;

  return (
    <FilterContextProvider filterId={FILTER_ID} initialValues={initialFilterValues}>
      {shouldShowCarousel && (
        <ReviewScoreCardCarousel
          reviewsData={dummyStipendsData}
          className="mb-4"
          data-testid="program-score-cards"
        />
      )}
      <FilterWarningBanner
        filterKey={StipendFilter.STATUS}
        shouldShowWarning={(filterValues, reviewingCount) => {
          // Get status numbers for Open and In_Review
          const openStatusId = getStatusNumber(STATUS_KEYS.Open)?.toString();
          const inReviewStatusId = getStatusNumber(STATUS_KEYS.In_Review)?.toString();
          if (!openStatusId || !inReviewStatusId) return false;

          // Show the banner if filters are selected, not both key statuses are included, and items need attention
          return (
            filterValues.length > 0 &&
            !(filterValues.includes(openStatusId) && filterValues.includes(inReviewStatusId)) &&
            reviewingCount?.stipend_claims_count > 0
          );
        }}
        messageText="claims"
      />
      <LeanComptTableContextProvider
        editMode={isEditingMode}
        setIsEditMode={setIsEditingMode}
        columnDefinition={columnDefinition}
        tableId={FILTER_ID}
      >
        <ReviewStipendTable />
      </LeanComptTableContextProvider>
      <ComptPortal portalNode={PortalRoot.RIGHT_NAV}>
        <ReviewStipendSidebar />
      </ComptPortal>
    </FilterContextProvider>
  );
};
