// RTK Queries
import {useGetDraftExpensesQuery} from '@compt/app/services/api/draft-expense-slice';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';

// Types
import {MAX_15_PAGE_LIMIT} from '@compt/constants';
import {DraftExpense} from './draft-expense.types';

// Components
import {DraftExpenseSidePanel} from './draft-expenses-side-panel';
import {LeanComptTable} from '@compt/common/lean-compt-table/lean-compt-table';
import {usePaginationQuery} from '@compt/pages/admin-pages/admin-review-programs/format-query-helper';

export const DraftExpensesTable = () => {
  const [, setSearchParams] = useSearchParams();
  const paginationQuery = usePaginationQuery(MAX_15_PAGE_LIMIT);

  const draftExpensesQuery = useGetDraftExpensesQuery({
    filter: {
      ...paginationQuery,
      limit: paginationQuery,
    },
  });
  const expenseDrafts = draftExpensesQuery.data;

  return (
    <>
      <DraftExpenseSidePanel />
      <LeanComptTable
        data={expenseDrafts?.results ?? []}
        isDataLoading={draftExpensesQuery.isLoading}
        noDataTitleText={'No expense drafts have been created yet!'}
        totalCount={expenseDrafts?.count ?? 0}
        itemsPerPage={MAX_15_PAGE_LIMIT}
        showPagination
        onRowClicked={(row: DraftExpense) => {
          setSearchParams({draft_id: `${row.id}`});
        }}
      />
    </>
  );
};
