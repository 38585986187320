import React, {useEffect} from 'react';

// Hooks and methods
import {UseFormReturn} from 'react-hook-form';
import {EmployeeBusinessExpenseFormController as controller} from './employee-business-expense-form.controller';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

// Types
import {CostCenter} from '@compt/types/cost-center';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';
import {
  BusinessExpenseType,
  BusinessExpenseReimbursementType,
} from '@compt/types/business-expenses/business-expense';
import {Company} from '@compt/types/company';

// Components
import {ComputedRateText} from './computed-rate-text';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {RouteAndDateRangeFields} from './route-and-date-range-fields';
import {SharedBusinessExpenseFields} from './shared-business-expense-fields';
import {BusinessExpenseReimbursementTypeInput} from '../business-expense-reimbursement-type-input';

type PerDiemExpenseFormProps = {
  formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>;
  assignedCostCenter: CostCenter | undefined;
  company: Company;
  isEditable?: boolean;
  isCreateForm?: boolean;
};

export const PerDiemExpenseForm = (props: PerDiemExpenseFormProps) => {
  const {isCreateForm, assignedCostCenter, company, formMethods} = props;
  const isDisabled = !props.isEditable;

  const perDiemRate = company.per_diem_rate;
  const canSelectCostCenter =
    company.business_expense_configuration?.users_may_select_cost_center || false;

  const defaultPerDiemCategory =
    company.business_expense_configuration?.default_per_diem_expense_category;

  const watchedReimbursementType = formMethods.watch('reimbursement_type');

  useEffect(() => {
    if (isCreateForm) {
      controller.resetFields(formMethods, assignedCostCenter);
      formMethods.resetField('expense_category', {
        defaultValue: defaultPerDiemCategory,
      });
      formMethods.resetField('per_diem_rate', {defaultValue: perDiemRate});
      formMethods.resetField('reimbursement_type', {defaultValue: null});

      controller.validateCostCenterOnCreate(canSelectCostCenter, assignedCostCenter, formMethods);
      controller.validateExpenseCategoryOnCreate(defaultPerDiemCategory, formMethods);
    }
  }, [
    isCreateForm,
    formMethods,
    assignedCostCenter,
    perDiemRate,
    defaultPerDiemCategory,
    canSelectCostCenter,
  ]);

  return (
    <div>
      {featureEnabled(FeatureFlags.REIMBURSEMENT_BUSINESS_EXPENSE_TYPE) && (
        <BusinessExpenseReimbursementTypeInput
          selectedOption={watchedReimbursementType}
          formMethods={formMethods}
          // TO-DO: Update in COMPT-6425
          disabledOptions={isDisabled ? [BusinessExpenseReimbursementType.REIMBURSEMENT] : []}
          errors={formMethods.formState.errors.reimbursement_type}
          data-testid="reimbursement-type"
          validation={{required: 'Reimbursement type is required'}}
        />
      )}
      <RouteAndDateRangeFields
        formMethods={formMethods}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <ComptCurrencyField
        name="per_diem_rate"
        label="Per diem rate"
        data-testid="per-diem-rate-field"
        control={formMethods.control}
        register={formMethods.register}
        errors={formMethods.formState.errors.per_diem_rate}
        validation={{
          required: 'Per diem rate is required',
          min: 0.01,
        }}
        placeholder="0.00"
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <SharedBusinessExpenseFields
        formMethods={formMethods}
        company={company}
        expenseCategoryDisabled={!!defaultPerDiemCategory}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />{' '}
      <ComputedRateText expenseType={BusinessExpenseType.PER_DIEM} form={formMethods.watch()} />
    </div>
  );
};
