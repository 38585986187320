/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * Available feature flags.
 *
 * @type {{ADP_INTEGRATION: string}}
 */

export enum FeatureFlags {
  ADP_INTEGRATION = 'feature_adp_enabled',
  INTERNATIONAL = 'international',
  LIMIT_RECOGNITION_FUNCTIONALITY = 'feature_limit_recognition_functionality',
  MULTI_GROUP_COLUMN_MAX_COUNT = 'feature_group_column_max_count',
  RECOGNITION_EMAIL_CC = 'feature_recognition_email_cc',
  BUSINESS_EXPENSES_ENABLED = 'feature_business_expenses',
  BUSINESS_EXPENSES = 'feature_business_expenses',
  REACT_SIDE_NAV = 'feature_react_side_nav',
  BUSINESS_EXPENSE_OMIT_SUMMARY_COLUMN = 'feature_be_omit_summary_column',
  ENABLE_NEW_REACT_APP = 'feature_enable_new_react_app',
  USER_DISABLED_NEW_REACT_APP = 'feature_user_disabled_new_react_app',
  USER_HIDE_NDS_POST_SUBMIT_SURVEY = 'feature_user_hide_nds_post_submit_survey',
  ENABLE_ADMIN_NDS = 'feature_enable_admin_nds',
  TEAM_ROSTER_SYNC_LOGS = 'feature_team_roster_sync_logs',
  VENDOR_DISCOVERY = 'feature_vendor_discovery',
  ENABLE_LEARNING_AND_DEVELOPMENT = 'feature_enable_learning_and_development',
  COMMUNITY_FEED = 'feature_community_feed',
  EXPENSE_OCR = 'feature_expense_ocr',
  CONSOLIDATE_PROGRAMS = 'feature_consolidate_programs',
  SHOW_REVIEW_SCORECARDS = 'feature_show_review_scorecards',
  EMAIL_TO_DRAFT = 'feature_email_to_draft',
  REIMBURSEMENT_BUSINESS_EXPENSE_TYPE = 'feature_business_expense_reimbursement_type',
}

export const getFeatureFlagValue = (featureKey: FeatureFlags, defaultValue = null) => {
  const windowFeatures = window.features;

  if (!window || !windowFeatures || !(featureKey in windowFeatures)) {
    return defaultValue;
  }
  return windowFeatures[featureKey];
};

/**
 * Returns whether the given feature is enabled
 *
 * @param feature
 * @returns {boolean}
 */
export const featureEnabled = (feature: FeatureFlags) => getFeatureFlagValue(feature) === 'enabled';
