import React from 'react';

// Components
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptLink} from '@compt/common/compt-link/compt-link';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

interface ExpensePolicyButtonProps {
  company: {
    business_expense_configuration?: {
      business_expense_policy?: string;
      link?: string;
    };
  };
  setOpenPolicyModal: (open: boolean) => void;
  isMobileView: boolean;
}

export const ExpensePolicyButton: React.FC<ExpensePolicyButtonProps> = ({
  company,
  setOpenPolicyModal,
  isMobileView = false,
}) => {
  const renderExpensePolicy = () => (
    <p className="flex body2 text-color-body1">View expense policy</p>
  );

  if (company.business_expense_configuration?.business_expense_policy) {
    return (
      <ComptButton
        buttonType={ComptButtonType.BORDERLESS}
        iconId={ComptButtonIcon.DOCUMENT_BLUE}
        data-testid="view-expense-policy-button"
        className={isMobileView ? 'w-full mb-2' : 'mb-2 items-center'}
        onClick={() => setOpenPolicyModal(true)}
      >
        {renderExpensePolicy()}
      </ComptButton>
    );
  } else if (company.business_expense_configuration?.link) {
    return (
      <ComptLink
        link={company.business_expense_configuration.link}
        newTab={true}
        className={isMobileView ? 'w-full mb-2' : 'inline-flex'}
        textClassName={
          isMobileView ? 'flex items-center justify-center mb-2' : 'flex items-center mb-1 mr-2'
        }
      >
        <ComptSvgIcon
          iconName="document-icon-blue"
          labelHidden={true}
          ariaLabel="document-icon"
          className="mr-2"
        />
        {renderExpensePolicy()}
      </ComptLink>
    );
  }

  return null;
};
