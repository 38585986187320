import React from 'react';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {Allotment} from '@compt/types/allotments';

type CardExpirationIndicatorProps = {
  allotment: Allotment;
  isMobile?: boolean;
};

export const CardExpirationIndicator = ({allotment}: CardExpirationIndicatorProps) => {
  const renderAvailableTime = (timeLeft: number, timeUnit: string) => (
    <p className="label4 text-color-body1 mt-100 sm:mt-000">
      Expires in {timeLeft}&nbsp;{timeUnit}
      {timeLeft === 1 ? '' : 's'}
    </p>
  );

  const availableTime = () => {
    if (allotment.days_left === undefined || allotment.days_left === null) {
      return;
    }

    if (allotment.days_left < 1) {
      if (allotment.minutes_left < 60) {
        return renderAvailableTime(allotment.minutes_left, 'minute');
      }
      return renderAvailableTime(Math.floor(allotment.minutes_left / 60), 'hour');
    }
    return renderAvailableTime(allotment.days_left, 'day');
  };

  const renderExpirationIndicator = () => {
    const daysLeftPercentage = allotment.percent_days_left;

    if (daysLeftPercentage === undefined || daysLeftPercentage === null) {
      return;
    }

    if (daysLeftPercentage <= 5) {
      return (
        <ComptSvgIcon iconName="expiration-indicator-95" className="mt-100 sm:mt-000 mr-100" />
      );
    } else if (daysLeftPercentage > 5 && daysLeftPercentage <= 25) {
      return (
        <ComptSvgIcon iconName="expiration-indicator-75" className="mt-100 sm:mt-000 mr-100" />
      );
    } else if (daysLeftPercentage > 25 && daysLeftPercentage <= 50) {
      return (
        <ComptSvgIcon iconName="expiration-indicator-50" className="mt-100 sm:mt-000 mr-100" />
      );
    } else {
      return <ComptSvgIcon iconName="expiration-indicator-0" className="mt-100 sm:mt-000 mr-100" />;
    }
  };

  return (
    <div className="flex items-center sm:justify-between">
      {renderExpirationIndicator()}
      {availableTime()}
    </div>
  );
};
