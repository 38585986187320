import React, {useEffect} from 'react';

// Hooks and methods
import {UseFormReturn} from 'react-hook-form';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {EmployeeBusinessExpenseFormController as controller} from './employee-business-expense-form.controller';

// Types
import {BusinessExpenseReimbursementType} from '@compt/types/business-expenses/business-expense';
import {Company} from '@compt/types/company';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';
import {CostCenter} from '@compt/types/cost-center';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';

// Components
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {SharedBusinessExpenseFields} from './shared-business-expense-fields';
import {BusinessExpenseReimbursementTypeInput} from '../business-expense-reimbursement-type-input';

type StandardBusinessExpenseFormProps = {
  formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>;
  assignedCostCenter: CostCenter | undefined;
  company: Company;
  isCreateForm?: boolean;
  isEditable?: boolean;
};

export const StandardBusinessExpenseForm = (props: StandardBusinessExpenseFormProps) => {
  const {isCreateForm, assignedCostCenter, company, formMethods} = props;
  const isDisabled = !props.isEditable;

  const canSelectCostCenter = company.business_expense_configuration?.users_may_select_cost_center;

  const watchedVendor = formMethods.watch('vendor');
  const watchedReimbursementType = formMethods.watch('reimbursement_type');

  useEffect(() => {
    if (isCreateForm) {
      controller.resetFields(formMethods, assignedCostCenter);
      formMethods.resetField('expense_category', {defaultValue: null});
      formMethods.resetField('reimbursement_type', {defaultValue: null});

      controller.validateCostCenterOnCreate(canSelectCostCenter, assignedCostCenter, formMethods);
    }
  }, [isCreateForm, formMethods, assignedCostCenter, canSelectCostCenter]);

  return (
    <div>
      {featureEnabled(FeatureFlags.REIMBURSEMENT_BUSINESS_EXPENSE_TYPE) && (
        <BusinessExpenseReimbursementTypeInput
          selectedOption={watchedReimbursementType}
          formMethods={formMethods}
          // TO-DO: Update in COMPT-6425
          disabledOptions={isDisabled ? [BusinessExpenseReimbursementType.REIMBURSEMENT] : []}
          errors={formMethods.formState.errors.reimbursement_type}
          data-testid="reimbursement-type"
          validation={{required: 'Reimbursement type is required'}}
        />
      )}
      <ComptDatePickerField
        name="receipt_date"
        label="Receipt date"
        control={formMethods.control}
        register={formMethods.register}
        validation={{required: 'Receipt date is required'}}
        errors={formMethods.formState.errors.receipt_date}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <ComptTextField
        name="vendor"
        label="Vendor"
        data-testid="vendor-field"
        control={formMethods.control}
        register={formMethods.register}
        validation={{
          required: 'Vendor is required',
          maxLength: {
            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
            message: `Vendor has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
          },
        }}
        errors={formMethods.formState.errors.vendor}
        disabled={isDisabled}
        readOnly={!props.isEditable}
        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
        watchedValue={watchedVendor}
      />
      <ComptCurrencyField
        name="amount"
        label="Amount"
        data-testid="amount-field"
        control={formMethods.control}
        register={formMethods.register}
        errors={formMethods.formState.errors.amount}
        validation={{
          required: 'Amount is required',
          min: 0.01,
        }}
        placeholder="0.00"
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <SharedBusinessExpenseFields
        formMethods={formMethods}
        company={company}
        expenseCategoryDisabled={false}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
    </div>
  );
};
