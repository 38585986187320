// Hooks and methods
import {USER_ROLES} from '../../utils/user-roles-helper';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

// Types
import {UserSession} from '@compt/types/account';
import {SideNavItemKeys, SideNavItemType} from '../compt-side-nav/compt-side-nav.types';

// Components
import {BaseIconName} from '../compt-side-nav/compt-side-nav-button';

export enum SECTION_TYPE {
  EMPLOYEE = 'EMPLOYEE',
  GENERAL_ADMIN = 'GENERAL_ADMIN',
  STIPEND_ADMIN = 'STIPEND_ADMIN',
  EXPENSE_ADMIN = 'EXPENSE_ADMIN',
  INTERNAL_ADMIN = 'INTERNAL_ADMIN',
  LEARNING_ADMIN = 'LEARNING_ADMIN',
  MISC = 'MISC',
}

type ItemType = {
  sideNavItem: SideNavItemType;
  eligibleUserRoles: string[];
  viewableByHijacker?: boolean;
  requiredFeature?: FeatureFlags;
  hideInMobile?: boolean;
  disabled?: boolean;
};

export type SectionType = {
  title: string;
  type: SECTION_TYPE;
  isCollapsible: boolean;
  eligibleUserRoles: string[];
  viewableByHijacker: boolean;
  items: ItemType[];
  requiredFeature?: FeatureFlags;
  disabled?: boolean;
  sectionIcon?: string;
};

function getAdminNDSPagePath(onDjangoTemplatePage: boolean) {
  if (onDjangoTemplatePage)
    return {
      be_review_page_path: '/app/#/review-programs/business-expenses',
      be_search_page_path: '/app/#/search-business-expenses',
      custom_notifications_path: '/app/#/customizable-notifications',
    };
  return {
    be_review_page_path: '/review-programs/business-expenses',
    be_search_page_path: '/search-business-expenses',
    custom_notifications_path: '/customizable-notifications',
  };
}

export class ComptLayoutController {
  getEligibleNavSectionsAndItems(
    userDataState?: UserSession,
    onDjangoTemplatePage?: boolean,
    isMobileView?: boolean,
  ): SectionType[] | null {
    if (!userDataState) {
      return null;
    }

    const {is_hijacked, hijacker_is_superuser, roles} = userDataState;
    const navRoleDefinitions = getNavRoleDefinitions(onDjangoTemplatePage);

    const canViewSection = (
      section: SectionType,
      isHijacked: boolean,
      isSuperuser: boolean,
      userRoles: string[],
    ) => {
      if (isHijacked && !(section.viewableByHijacker || isSuperuser)) return false;
      return (
        this.isFeatureEnabled(section) &&
        section.eligibleUserRoles.some((role) => userRoles.includes(role))
      );
    };

    const canViewItem = (
      item: ItemType,
      isMobile: boolean,
      isHijacked: boolean,
      isSuperuser: boolean,
      userRoles: string[],
    ) => {
      if ((isMobile && item.hideInMobile) || item.disabled) return false;
      if (isHijacked && !(item.viewableByHijacker || isSuperuser)) return false;
      return (
        this.isFeatureEnabled(item) &&
        item.eligibleUserRoles.some((role) => userRoles.includes(role))
      );
    };

    return navRoleDefinitions
      .filter((section) => canViewSection(section, is_hijacked, hijacker_is_superuser, roles))
      .map((section) => ({
        ...section,
        items: section.items.filter((item) =>
          canViewItem(item, !!isMobileView, is_hijacked, hijacker_is_superuser, roles),
        ),
      }));
  }

  private isFeatureEnabled(sectionOrNavItem: ItemType | SectionType) {
    return (
      sectionOrNavItem.requiredFeature === undefined ||
      featureEnabled(sectionOrNavItem.requiredFeature)
    );
  }
}

function getNavRoleDefinitions(onDjangoTemplatePage?: boolean): SectionType[] {
  return [
    {
      title: 'Employee',
      type: SECTION_TYPE.EMPLOYEE,
      isCollapsible: false,
      eligibleUserRoles: [
        USER_ROLES.stipendEligible,
        USER_ROLES.businessExpenseEligible,
        USER_ROLES.delegate,
      ],
      viewableByHijacker: true,
      items: [
        {
          viewableByHijacker: true,
          sideNavItem: {
            key: SideNavItemKeys.MY_STIPEND,
            title: 'My stipends',
            iconId: BaseIconName.BANK_NOTE,
            pagePath: onDjangoTemplatePage ? '/app/#/' : '/',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.ACTIVITY,
            title: 'Stipend activity',
            iconId: BaseIconName.ACTIVITY,
            pagePath: onDjangoTemplatePage
              ? '/app/#/activity/claims-submitted'
              : '/activity/claims-submitted',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible],
          viewableByHijacker: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BUSINESS_EXPENSE,
            title: 'Business expenses',
            iconId: BaseIconName.BRIEFCASE,
            pagePath: onDjangoTemplatePage ? '/app/#/business-expenses/' : '/business-expenses',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.businessExpenseEligible],
          requiredFeature: FeatureFlags.BUSINESS_EXPENSES,
          viewableByHijacker: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.TEAM_RECOGNITION,
            title: 'Team recognition',
            iconId: BaseIconName.AWARD,
            pagePath: onDjangoTemplatePage ? '/app/#/team-recognition/' : '/team-recognition',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible],
          viewableByHijacker: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.LEARNING,
            title: 'My development',
            iconId: 'book-icon',
            pagePath: onDjangoTemplatePage ? '/app/#/learning/' : '/learning',
            isHref: onDjangoTemplatePage,
          },
          requiredFeature: FeatureFlags.ENABLE_LEARNING_AND_DEVELOPMENT,
          eligibleUserRoles: [USER_ROLES.learningAndDevelopmentEligible],
          viewableByHijacker: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.COMMUNITY,
            title: 'Recommendations',
            iconId: BaseIconName.GLOBE4,
            pagePath: onDjangoTemplatePage ? '/app/#/recommendations/' : '/recommendations',
            isHref: onDjangoTemplatePage,
          },
          requiredFeature: FeatureFlags.COMMUNITY_FEED,
          eligibleUserRoles: [USER_ROLES.learningAndDevelopmentEligible],
          viewableByHijacker: true,
          hideInMobile: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.EXPLORE,
            title: 'Explore',
            iconId: BaseIconName.SEARCH,
            pagePath: onDjangoTemplatePage ? '/app/#/explore/' : '/explore',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible],
          requiredFeature: FeatureFlags.VENDOR_DISCOVERY,
          viewableByHijacker: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.FAQ,
            title: 'FAQs',
            iconId: BaseIconName.ANNOTATION_QUESTION,
            pagePath: onDjangoTemplatePage ? '/app/#/faq' : '/faq',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible, USER_ROLES.businessExpenseEligible],
          viewableByHijacker: false,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.ASSIGNMENTS,
            title: 'Assignments',
            iconId: BaseIconName.CHECK_DONE,
            pagePath: onDjangoTemplatePage ? '/app/#/accounts/delegate' : '/accounts/delegate',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.delegate],
          viewableByHijacker: false,
        },
      ],
    },
    {
      title: 'General admin',
      type: SECTION_TYPE.GENERAL_ADMIN,
      isCollapsible: false,
      eligibleUserRoles: [
        USER_ROLES.stipendReviewer,
        USER_ROLES.employeeManager,
        USER_ROLES.financeReviewer,
        USER_ROLES.learningAndDevelopmentPrimaryReviewer,
        USER_ROLES.learningAndDevelopmentSecondaryReviewer,
        USER_ROLES.learningAndDevelopmentReimbursementReviewer,
        USER_ROLES.payrollProcessor,
      ],
      viewableByHijacker: true,
      items: [
        {
          viewableByHijacker: true,
          sideNavItem: {
            key: SideNavItemKeys.REVIEW_CLAIMS,
            title: 'Review claims',
            iconId: 'file-check-icon',
            pagePath: onDjangoTemplatePage ? '/app/#/review-programs' : '/review-programs',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [
            USER_ROLES.stipendReviewer,
            USER_ROLES.employeeManager,
            USER_ROLES.financeReviewer,
            USER_ROLES.learningAndDevelopmentPrimaryReviewer,
            USER_ROLES.learningAndDevelopmentSecondaryReviewer,
            USER_ROLES.learningAndDevelopmentReimbursementReviewer,
          ],
          requiredFeature: FeatureFlags.CONSOLIDATE_PROGRAMS,
        },
        {
          viewableByHijacker: true,
          sideNavItem: {
            key: SideNavItemKeys.PAYROLL,
            title: 'Payroll',
            iconId: BaseIconName.COIN_SWAP,
            pagePath: '/payroll/summary',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.payrollProcessor],
          requiredFeature: FeatureFlags.CONSOLIDATE_PROGRAMS,
        },
      ],
    },
    {
      title: 'Stipend admin',
      type: SECTION_TYPE.STIPEND_ADMIN,
      isCollapsible: true,
      sectionIcon: 'wallet-icon-gray',
      eligibleUserRoles: [
        USER_ROLES.stipendProgramManager,
        USER_ROLES.stipendReviewer,
        USER_ROLES.auditor,
        USER_ROLES.admin,
      ],
      viewableByHijacker: false,
      items: [
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_DASHBOARD,
            title: 'Dashboard',
            iconId: BaseIconName.BAR_CHART,
            pagePath: '/dashboard',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.stipendProgramManager, USER_ROLES.auditor],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_STIPENDS,
            title: 'Stipends',
            iconId: BaseIconName.BANK_NOTE,
            pagePath: '/manage/#/stipends',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.stipendProgramManager],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_TEAM,
            title: 'Team',
            iconId: BaseIconName.USERS,
            pagePath: '/company/team',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.stipendProgramManager],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_TEAM_SYNC_LOGS,
            title: 'Team sync logs',
            iconId: BaseIconName.USERS,
            pagePath: '/app/#/sync-attempts/',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.stipendProgramManager],
          requiredFeature: FeatureFlags.TEAM_ROSTER_SYNC_LOGS,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_PAYROLL,
            title: 'Payroll',
            iconId: BaseIconName.COIN_SWAP,
            pagePath: '/payroll/summary',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.payrollProcessor, USER_ROLES.stipendReviewer],
          disabled: featureEnabled(FeatureFlags.CONSOLIDATE_PROGRAMS),
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_FAQ,
            title: 'Manage FAQs',
            iconId: BaseIconName.ANNOTATION_QUESTION,
            pagePath: '/company/faqs',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.stipendProgramManager],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_CUSTOMIZABLE_NOTIFICATIONS,
            title: 'Notifications',
            iconId: BaseIconName.PIE_CHART,
            pagePath: getAdminNDSPagePath(!!onDjangoTemplatePage).custom_notifications_path,
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.admin],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_COMPANY_SETTINGS,
            title: 'Company settings',
            iconId: BaseIconName.SETTINGS,
            pagePath: '/company/settings',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.admin],
        },
      ],
    },
    {
      title: 'Expense admin',
      type: SECTION_TYPE.EXPENSE_ADMIN,
      isCollapsible: true,
      sectionIcon: 'coin-stacked-icon-gray',
      eligibleUserRoles: [
        USER_ROLES.businessExpenseProgramManager,
        USER_ROLES.employeeManager,
        USER_ROLES.financeReviewer,
      ],
      requiredFeature: FeatureFlags.BUSINESS_EXPENSES,
      viewableByHijacker: false,
      items: [
        {
          sideNavItem: {
            key: SideNavItemKeys.BE_ADMIN_REPORTING,
            title: 'Reporting',
            iconId: BaseIconName.BAR_CHART,
            pagePath: '/business_expense_dashboard',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.auditor],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BE_ADMIN_REVIEW_EXPENSES,
            title: 'Review expenses',
            iconId: BaseIconName.CHECK_DONE,
            pagePath: getAdminNDSPagePath(!!onDjangoTemplatePage).be_review_page_path,
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.financeReviewer, USER_ROLES.employeeManager],
          disabled: featureEnabled(FeatureFlags.CONSOLIDATE_PROGRAMS),
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BE_ADMIN_SEARCH_EXPENSES,
            title: 'Search expenses',
            iconId: BaseIconName.SEARCH_REFRACTION,
            pagePath: getAdminNDSPagePath(!!onDjangoTemplatePage).be_search_page_path,
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.financeReviewer, USER_ROLES.employeeManager],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BE_ADMIN_TEAM,
            title: 'Team',
            iconId: BaseIconName.USERS,
            pagePath: '/company/team',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.businessExpenseProgramManager],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BE_ADMIN_PAYROLL,
            title: 'Payroll',
            iconId: BaseIconName.COIN_SWAP,
            pagePath: '/payroll/summary',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.payrollProcessor],
          disabled: featureEnabled(FeatureFlags.CONSOLIDATE_PROGRAMS),
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BE_ADMIN_SETTINGS,
            title: 'Expense settings',
            iconId: BaseIconName.SETTINGS,
            pagePath: '/business_expenses/settings/',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.admin],
        },
      ],
    },
    {
      title: 'Development admin',
      type: SECTION_TYPE.LEARNING_ADMIN,
      isCollapsible: true,
      sectionIcon: 'bank-note-02-icon-gray',
      eligibleUserRoles: [
        USER_ROLES.learningDevelopmentProgramManager,
        ...(!featureEnabled(FeatureFlags.CONSOLIDATE_PROGRAMS)
          ? [
              USER_ROLES.learningAndDevelopmentPrimaryReviewer,
              USER_ROLES.learningAndDevelopmentSecondaryReviewer,
              USER_ROLES.learningAndDevelopmentReimbursementReviewer,
              USER_ROLES.employeeManager,
            ]
          : []),
      ],
      viewableByHijacker: true,
      requiredFeature: FeatureFlags.ENABLE_LEARNING_AND_DEVELOPMENT,
      items: [
        {
          sideNavItem: {
            key: SideNavItemKeys.LND_ADMIN_REPORTING,
            title: 'Reporting',
            iconId: BaseIconName.BAR_CHART,
            pagePath: '/learning_and_development_dashboard',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.auditor],
        },
        {
          viewableByHijacker: true,
          sideNavItem: {
            key: 'learning_and_development_admin',
            title: 'Manage programs',
            pagePath: onDjangoTemplatePage ? '/app/#/manage-programs' : '/manage-programs',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.learningDevelopmentProgramManager],
        },
        {
          sideNavItem: {
            key: 'learning_and_development_review',
            title: 'Review requests',
            pagePath: onDjangoTemplatePage
              ? '/app/#/review-programs/professional-development'
              : '/review-programs/professional-development',
            isHref: onDjangoTemplatePage,
          },
          requiredFeature: FeatureFlags.ENABLE_LEARNING_AND_DEVELOPMENT,
          disabled: featureEnabled(FeatureFlags.CONSOLIDATE_PROGRAMS),
          eligibleUserRoles: [
            USER_ROLES.learningAndDevelopmentPrimaryReviewer,
            USER_ROLES.learningAndDevelopmentSecondaryReviewer,
            USER_ROLES.learningAndDevelopmentReimbursementReviewer,
            USER_ROLES.employeeManager,
          ],
        },
      ],
    },
    {
      title: 'Internal admin',
      type: SECTION_TYPE.INTERNAL_ADMIN,
      isCollapsible: true,
      sectionIcon: 'settings-icon-gray',
      eligibleUserRoles: [USER_ROLES.internalAdmin],
      viewableByHijacker: false,
      items: [
        {
          sideNavItem: {
            key: SideNavItemKeys.DJANGO_ADMIN,
            title: 'Django admin',
            iconId: BaseIconName.PIE_CHART,
            pagePath: '/admin',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.internalAdmin],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.COMPT_ADMIN,
            title: 'Compt admin',
            iconId: BaseIconName.PIE_CHART,
            pagePath: '/superboard',
            isHref: true,
          },
          eligibleUserRoles: [USER_ROLES.internalAdmin],
        },
      ],
    },
  ];
}
