import {ListResultsBase} from '../common/list-results';
import {CostCenter} from '../cost-center';
import {SupportingDocument} from '../supporting-document';
import {BusinessExpenseCategory} from './business-expense-category';
import {BusinessExpenseReport} from './business-expense-report';
import {BusinessExpenseStatus} from './business-expense-statuses';

export enum BusinessExpenseType {
  BUSINESS_EXPENSE = 'BUSINESS_EXPENSE',
  PER_DIEM = 'PER_DIEM',
  MILEAGE = 'MILEAGE',
}

export const expenseTypeFormats: Record<BusinessExpenseType, string> = {
  BUSINESS_EXPENSE: 'Business Expense',
  PER_DIEM: 'Per Diem',
  MILEAGE: 'Mileage',
};

export type BusinessExpenseListResults = ListResultsBase<BusinessExpense>;

export enum BusinessExpenseReimbursementType {
  REIMBURSEMENT = 'Reimbursement',
  REPORTING_ONLY = 'Reporting Only',
}

export interface BusinessExpense {
  id: number;
  status: BusinessExpenseStatus;
  report_id: number;
  amount_of_expense: string;
  receipt_date?: string | Date;
  vendor?: string;
  expense_type: BusinessExpenseType;
  cost_center_id: number;
  category_id: number;
  category: BusinessExpenseCategory;
  cost_center: CostCenter;
  origin?: string;
  destination?: string;
  start_date?: string | Date | null;
  end_date?: string | Date | null;
  distance?: string;
  description: string;
  receipt_image?: string;
  receipt_key: string;
  manager_approval_on?: string;
  finance_approval_on?: string;
  rejection_reason?: string;
  reviewed_on?: string;
  closed_on?: string;
  manager_reviewer_id?: number;
  finance_reviewer_id?: number;
  per_diem_rate?: string;
  user_id: number;
  full_name: string;
  email: string;
  report: BusinessExpenseReport;
  supporting_documents: SupportingDocument[];
  reimbursement_type: BusinessExpenseReimbursementType | null;
}

export interface BusinessExpenseWithError extends BusinessExpense {
  error: boolean;
}
