import React, {useEffect} from 'react';

// Hooks and methods
import {UseFormReturn} from 'react-hook-form';
import {EmployeeBusinessExpenseFormController as controller} from './employee-business-expense-form.controller';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

// Types
import {CostCenter} from '@compt/types/cost-center';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';
import {
  BusinessExpenseType,
  BusinessExpenseReimbursementType,
} from '@compt/types/business-expenses/business-expense';
import {Company} from '@compt/types/company';

// Components
import {ComptNumberField} from '@compt/common/forms/compt-number-field/compt-number-field';
import {ComputedRateText} from './computed-rate-text';
import {RouteAndDateRangeFields} from './route-and-date-range-fields';
import {SharedBusinessExpenseFields} from './shared-business-expense-fields';
import {BusinessExpenseReimbursementTypeInput} from '../business-expense-reimbursement-type-input';

type MileageExpenseFormProps = {
  formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>;
  assignedCostCenter: CostCenter | undefined;
  company: Company;
  isEditable?: boolean;
  isCreateForm?: boolean;
};

export const MileageExpenseForm = (props: MileageExpenseFormProps) => {
  const {isCreateForm, assignedCostCenter, company, formMethods} = props;
  const isDisabled = !props.isEditable;

  const canSelectCostCenter =
    company.business_expense_configuration?.users_may_select_cost_center || false;

  const defaultMileageCategory =
    company.business_expense_configuration?.default_mileage_expense_category;

  const watchedReimbursementType = formMethods.watch('reimbursement_type');

  useEffect(() => {
    if (isCreateForm) {
      controller.resetFields(formMethods, assignedCostCenter);
      formMethods.resetField('expense_category', {
        defaultValue: defaultMileageCategory,
      });
      formMethods.resetField('reimbursement_type', {defaultValue: null});

      controller.validateCostCenterOnCreate(canSelectCostCenter, assignedCostCenter, formMethods);
      controller.validateExpenseCategoryOnCreate(defaultMileageCategory, formMethods);
    }
  }, [isCreateForm, formMethods, assignedCostCenter, defaultMileageCategory, canSelectCostCenter]);

  return (
    <div>
      {featureEnabled(FeatureFlags.REIMBURSEMENT_BUSINESS_EXPENSE_TYPE) && (
        <BusinessExpenseReimbursementTypeInput
          selectedOption={watchedReimbursementType}
          formMethods={formMethods}
          // TO-DO: Update in COMPT-6425
          disabledOptions={isDisabled ? [BusinessExpenseReimbursementType.REIMBURSEMENT] : []}
          errors={formMethods.formState.errors.reimbursement_type}
          data-testid="reimbursement-type"
          validation={{required: 'Reimbursement type is required'}}
        />
      )}
      <RouteAndDateRangeFields
        formMethods={formMethods}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <ComptNumberField
        name="distance"
        label="Distance (miles)"
        data-testid="distance-field"
        placeholder="ex. 265.5"
        stepValue=".1"
        decimalPlaces={1}
        control={formMethods.control}
        register={formMethods.register}
        // eslint-disable-next-line eqeqeq
        validation={{required: 'Distance is required', validate: (value) => value != 0}}
        errors={formMethods.formState.errors.distance}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <SharedBusinessExpenseFields
        formMethods={formMethods}
        company={company}
        expenseCategoryDisabled={!!defaultMileageCategory}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <ComputedRateText expenseType={BusinessExpenseType.MILEAGE} form={formMethods.watch()} />
    </div>
  );
};
