/* eslint-disable max-len */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import {comptApiSlice, ComptRTKTags} from './api-slice';
import {
  ReviewingUserListResults,
  LearningDevelopmentProgram,
  LearningDevelopmentProgramListResults,
  LearningDevelopmentProgramPayload,
  ProgramFundingRule,
  ProgramFundingRulePayload,
  ProgramReviewingConfigurationPayload,
  ProgramReviewingConfiguration,
  ReviewingUserPayload,
  RequestField,
  RequestFieldList,
  ReviewingStatusCount,
} from '@compt/types/learning-development/learning-development-program';
import {
  PreApprovalRequest,
  PreApprovalRequestListResults,
} from '@compt/types/learning-development/pre-approval-request';

export const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLearningDevelopmentProgram: builder.query<
      LearningDevelopmentProgram,
      {companyId?: number; programId?: number}
    >({
      query: ({companyId, programId}) =>
        `learning_and_development/${companyId}/admin/programs/${programId}`,
      providesTags: (_result, _error, payload) => [
        {
          type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
          id: payload.programId,
        },
      ],
    }),
    getAllLearningDevelopmentPrograms: builder.query<
      LearningDevelopmentProgramListResults,
      {companyId?: number; filter: {search?: string; offset: number; limit: number}}
    >({
      query: ({companyId, filter}) => ({
        url: `learning_and_development/${companyId}/admin/programs/`,
        params: filter,
        method: 'GET',
      }),
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST],
    }),
    createLearningDevelopmentProgram: builder.mutation<
      LearningDevelopmentProgram,
      {body: Partial<LearningDevelopmentProgramPayload>; companyId: number}
    >({
      query({body, companyId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
      ],
    }),
    updateLearningDevelopmentProgram: builder.mutation<
      LearningDevelopmentProgram,
      Partial<LearningDevelopmentProgram>
    >({
      query(body) {
        return {
          url: `learning_and_development/${body.company?.id}/admin/programs/${body.id}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.id},
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    deleteLearningDevelopmentProgram: builder.mutation<
      void,
      {companyId: number; programId: number}
    >({
      query({companyId, programId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateProgramFundingRule: builder.mutation<
      ProgramFundingRule[],
      {body: ProgramFundingRulePayload[]; programId: number; companyId: number}
    >({
      query({body, programId, companyId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/funding_rules/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
      ],
    }),
    getProgramAssignableUsers: builder.query<
      ReviewingUserListResults,
      {
        companyId: number;
        programId: number;
        filter: {search?: string; offset: number; limit: number};
      }
    >({
      query: ({companyId, programId, filter}) => ({
        url: `learning_and_development/${companyId}/admin/programs/${programId}/assignable_users`,
        params: filter,
        method: 'GET',
      }),
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_ASSIGNABLE_USERS_LIST],
    }),
    createLearningDevelopmentProgramReviewingConfiguration: builder.mutation<
      ProgramReviewingConfiguration,
      {body: Partial<ProgramReviewingConfigurationPayload>}
    >({
      query({body}) {
        return {
          url: `learning_and_development/${body.company?.id}/admin/programs/${body.program?.id}/reviewing_configuration/`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.body.program?.id},
      ],
    }),
    updateLearningDevelopmentProgramReviewingConfiguration: builder.mutation<
      ProgramReviewingConfiguration,
      {body: Partial<ProgramReviewingConfiguration>; configurationId?: number}
    >({
      query({body, configurationId}) {
        return {
          url: `learning_and_development/${body.company?.id}/admin/programs/${body.program?.id}/reviewing_configuration/${configurationId}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.body.program?.id},
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateLearningDevelopmentProgramPrimaryApprovers: builder.mutation<
      ProgramReviewingConfiguration,
      {
        body: Array<ReviewingUserPayload>;
        companyId: number;
        programId: number;
        configurationId: number;
      }
    >({
      query({body, companyId, programId, configurationId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reviewing_configuration/${configurationId}/primary_approvers/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateLearningDevelopmentProgramSecondaryApprovers: builder.mutation<
      ProgramReviewingConfiguration,
      {
        body: Array<ReviewingUserPayload>;
        companyId: number;
        programId: number;
        configurationId: number;
      }
    >({
      query({body, companyId, programId, configurationId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reviewing_configuration/${configurationId}/secondary_approvers/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateLearningDevelopmentProgramReimbursementReviewers: builder.mutation<
      ProgramReviewingConfiguration,
      {
        body: Array<ReviewingUserPayload>;
        companyId: number;
        programId: number;
        configurationId: number;
      }
    >({
      query({body, companyId, programId, configurationId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reviewing_configuration/${configurationId}/reimbursement_reviewers/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    createPreApprovalRequestConfiguration: builder.mutation<
      LearningDevelopmentProgram,
      {programId: number; companyId: number}
    >({
      query({programId, companyId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/pre_approval_request_configurations`,
          method: 'GET',
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    createPreApprovalRequestField: builder.mutation<
      RequestField,
      {
        companyId: number;
        programId: number;
        configurationId: number;
        body: Partial<RequestField>;
      }
    >({
      query({companyId, programId, configurationId, body}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/pre_approval_request_configurations/${configurationId}/fields/`,
          method: 'POST',
          body: {...body},
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
      ],
    }),
    updatePreApprovalRequestField: builder.mutation<
      RequestField,
      {
        companyId: number;
        programId: number;
        configurationId: number;
        fieldId: number;
        body: Partial<RequestField>;
      }
    >({
      query({companyId, programId, configurationId, fieldId, body}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/pre_approval_request_configurations/${configurationId}/fields/${fieldId}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
      ],
    }),
    updatePreApprovalRequestFieldOrder: builder.mutation<
      RequestField[],
      {
        companyId: number;
        programId: number;
        configurationId: number;
        body: RequestField[];
      }
    >({
      query({companyId, programId, configurationId, body}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/pre_approval_request_configurations/${configurationId}/fields_reorder`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted({companyId, programId, body}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          extendedComptApiSlice.util.updateQueryData(
            'getLearningDevelopmentProgram',
            {programId: programId, companyId: companyId},
            (draft) => {
              // For each field in body, update the matching field within pre_approval_configuration if the ID is found..
              body.forEach((field) => {
                const fieldIndex = draft.pre_approval_configuration.request_fields.findIndex(
                  (f: RequestField) => f.id === field.id,
                );
                if (fieldIndex === -1) return;

                draft.pre_approval_configuration.request_fields[fieldIndex] = field;
              });
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error in optimistic update, undoing patch:', error);
          patchResult.undo();
        }
      },
    }),
    deletePreApprovalRequestField: builder.mutation<
      RequestField,
      {
        companyId: number;
        programId: number;
        configurationId: number;
        fieldId: number;
      }
    >({
      query({companyId, programId, configurationId, fieldId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/pre_approval_request_configurations/${configurationId}/fields/${fieldId}/`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
      ],
    }),
    createReimbursementRequestConfiguration: builder.mutation<
      LearningDevelopmentProgram,
      {programId: number; companyId: number}
    >({
      query({programId, companyId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reimbursement_request_configurations`,
          method: 'GET',
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    getReimbursementRequestFields: builder.query<
      RequestFieldList,
      {companyId?: number; programId?: number}
    >({
      query: ({companyId, programId}) =>
        `learning_and_development/${companyId}/admin/programs/${programId}/reimbursement_request_fields/`,
      providesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.REIMBURSEMENT_REQUEST_FIELDS, id: payload.programId},
      ],
    }),
    createReimbursementRequestField: builder.mutation<
      RequestField,
      {
        companyId: number;
        programId: number;
        body: Partial<RequestField>;
      }
    >({
      query({companyId, programId, body}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reimbursement_request_fields/`,
          method: 'POST',
          body: {...body},
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
        {type: ComptRTKTags.REIMBURSEMENT_REQUEST_FIELDS, id: payload.programId},
      ],
    }),
    updateReimbursementRequestField: builder.mutation<
      RequestField,
      {
        companyId: number;
        programId: number;
        fieldId: number;
        body: Partial<RequestField>;
      }
    >({
      query({companyId, programId, fieldId, body}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reimbursement_request_fields/${fieldId}/`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted({companyId, programId, body}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          extendedComptApiSlice.util.updateQueryData(
            'getReimbursementRequestFields',
            {programId, companyId},
            (draft) => {
              const fieldIndex = draft.results.findIndex((f: RequestField) => f.id === body.id);
              if (fieldIndex === -1) return;

              const draftRequestField = draft.results[fieldIndex];
              draft.results[fieldIndex] = {
                ...draftRequestField,
                ...body,
              };
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error in optimistic update, undoing patch:', error);
          patchResult.undo();
        }
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
      ],
    }),
    updateReimbursementRequestFieldOrder: builder.mutation<
      RequestField[],
      {
        companyId: number;
        programId: number;
        configurationId: number;
        body: RequestField[];
      }
    >({
      query({companyId, programId, configurationId, body}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reimbursement_request_configurations/${configurationId}/fields_reorder`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted({companyId, programId, body}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          extendedComptApiSlice.util.updateQueryData(
            'getReimbursementRequestFields',
            {programId: programId, companyId: companyId},
            (draft) => {
              draft.results = body;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error in optimistic update, undoing patch:', error);
          patchResult.undo();
        }
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
      ],
    }),
    deleteReimbursementRequestField: builder.mutation<
      RequestField,
      {
        companyId: number;
        programId: number;
        fieldId: number;
      }
    >({
      query({companyId, programId, fieldId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reimbursement_request_fields/${fieldId}/`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, _error, payload) => [
        {type: ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM, id: payload.programId},
      ],
    }),
    getAllAdminPreApprovalRequests: builder.query<
      PreApprovalRequestListResults,
      {companyId?: number; params?: Record<string, unknown> | null}
    >({
      query({companyId, params}) {
        return {
          url: `learning_and_development/${companyId}/review/pre_approval_requests/?include_reviewed=true`,
          params: {...params},
          method: 'GET',
        };
      },
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST],
    }),
    getAdminPreApprovalRequest: builder.query<
      PreApprovalRequest,
      {companyId: number | undefined; requestId: string | null}
    >({
      query({companyId, requestId}) {
        return {
          url: `learning_and_development/${companyId}/review/pre_approval_requests/${requestId}/`,
          method: 'GET',
        };
      },
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST],
    }),
    updateAdminPreApprovalRequest: builder.mutation<PreApprovalRequest, PreApprovalRequest>({
      query(body) {
        return {
          url: `learning_and_development/${body.pre_approval_configuration.company}/review/pre_approval_requests/${body.id}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST,
        ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST,
      ],
      // Note: Please see documentation: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates
      async onQueryStarted(arg, {dispatch, queryFulfilled, getState}) {
        const state = getState() as any;
        // Get the API queries called when a request status is updated
        const queries = Object.values(state.api.queries) as any[];
        // Identify the latest getAllAdminPreApprovalRequests query, and check if it was called
        // with status filters applied
        const lastQuery = queries
          .filter(
            (q: any) =>
              q.endpointName === 'getAllAdminPreApprovalRequests' &&
              q.status === 'fulfilled' &&
              q.originalArgs.companyId === arg.pre_approval_configuration.company,
          )
          .pop();
        const hasStatusFilter = lastQuery?.originalArgs.params?.status__in;

        try {
          if (!hasStatusFilter) {
            // No filters - do the update and refresh the list
            await queryFulfilled;
            dispatch(
              extendedComptApiSlice.util.invalidateTags([
                ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST,
              ]),
            );
          } else {
            // Has filters - do optimistic update without refresh
            const patchResult = dispatch(
              extendedComptApiSlice.util.updateQueryData(
                'getAllAdminPreApprovalRequests',
                lastQuery.originalArgs,
                (draft) => {
                  const requestToUpdate = draft.results.find((request) => request.id === arg.id);
                  if (requestToUpdate) {
                    requestToUpdate.status = arg.status;
                  }
                },
              ),
            );

            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          }
        } catch {
          console.error('Error occured while updating the request');
        }
      },
    }),
    getAdminReviewingStatusRequest: builder.query<ReviewingStatusCount, void>({
      query() {
        return {
          url: 'me/reviewing_status/',
          method: 'GET',
        };
      },
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST],
    }),
  }),
});

export const {
  useGetLearningDevelopmentProgramQuery,
  useCreateLearningDevelopmentProgramMutation,
  useDeleteLearningDevelopmentProgramMutation,
  useUpdateLearningDevelopmentProgramMutation,
  useGetAllLearningDevelopmentProgramsQuery,
  useUpdateProgramFundingRuleMutation,
  useGetProgramAssignableUsersQuery,
  useCreateLearningDevelopmentProgramReviewingConfigurationMutation,
  useCreatePreApprovalRequestFieldMutation,
  useDeletePreApprovalRequestFieldMutation,
  useUpdateLearningDevelopmentProgramReviewingConfigurationMutation,
  useUpdateLearningDevelopmentProgramPrimaryApproversMutation,
  useUpdateLearningDevelopmentProgramSecondaryApproversMutation,
  useUpdateLearningDevelopmentProgramReimbursementReviewersMutation,
  useCreatePreApprovalRequestConfigurationMutation,
  useUpdatePreApprovalRequestFieldMutation,
  useUpdatePreApprovalRequestFieldOrderMutation,
  useCreateReimbursementRequestConfigurationMutation,
  useGetReimbursementRequestFieldsQuery,
  useCreateReimbursementRequestFieldMutation,
  useUpdateReimbursementRequestFieldMutation,
  useUpdateReimbursementRequestFieldOrderMutation,
  useDeleteReimbursementRequestFieldMutation,
  useGetAllAdminPreApprovalRequestsQuery,
  useGetAdminPreApprovalRequestQuery,
  useUpdateAdminPreApprovalRequestMutation,
  useGetAdminReviewingStatusRequestQuery,
} = extendedComptApiSlice;
