import {useEffect, useMemo} from 'react';

// RTK queries
import {useGetAllotmentsQuery} from '@compt/app/services/api/allotments-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetDraftExpenseQuery} from '@compt/app/services/api/draft-expense-slice';
import {
  usePostToMSTeamsMutation,
  usePostToSlackMutation,
} from '@compt/app/services/api/notifications-slice';
import {
  CreateStipendBody,
  useCreateStipendExpenseMutation,
} from '@compt/app/services/api/stipend-expenses-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';

// Hooks and methods
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';
import {Analytics} from '@compt/utils/analytics/analytics-helpers';
import {extractDocumentFields, ObjectWithSupportingDocument} from '@compt/utils/file-helpers';
import {useScrollOutsideContainer} from '@compt/utils/scroll-helper';
import {FieldValues, useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {SaveClaimFormFieldValues} from '../../components/compt-save-claim-side-panel/compt-save-claim-side-panel.controller';
import {stripImageNameFromS3URL} from '@compt/utils/image-helpers';

// Types
import {DEFAULT_REIMBURSEMENT_INFO} from '@compt/constants';
import {ExpenseStatus} from '@compt/types/stipend-expense';
import {EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL} from '@compt/utils/analytics/types';

// Components
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComputedAmountSection} from '../../components/compt-save-claim-side-panel/computed-amount-section';
import {StipendFormContent} from '../../components/compt-save-claim-side-panel/stipend-form-content';

export const DraftExpenseSidePanel = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const draftId = searchParams.get('draft_id');

  const {contentRef} = useScrollOutsideContainer();

  const session = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(session.data?.user_id ?? skipToken);
  const customReimbursementInfo = companyQuery.data?.reimbursement_info;

  const allotmentQuery = useGetAllotmentsQuery(session.data?.user_id ?? skipToken);
  const allotments = allotmentQuery.data?.allotments;

  const showSlackField = companyQuery.data?.has_slack && session.data?.has_slack_token;
  const showMSTeamsField = companyQuery.data?.has_ms_teams;

  const draftExpenseQuery = useGetDraftExpenseQuery(
    {draftExpenseId: Number(draftId)},
    {skip: !draftId},
  );
  const draft = draftExpenseQuery.data;

  const [createStipend, {isLoading: isUpdating}] = useCreateStipendExpenseMutation();
  const [postToSlack] = usePostToSlackMutation();
  const [postToMSTeams] = usePostToMSTeamsMutation();

  const {receiptUploading, setReceiptUploading} = useReceiptUploadContext();

  const allotmentsWithBalance = useMemo(
    () =>
      allotments ? allotments.filter((allotment) => allotment.balance_amount > 0) : allotments,
    [allotments],
  );

  const formMethods = useForm<SaveClaimFormFieldValues>();
  const watchedAllotment = formMethods.watch('allotment');

  const isSubmitted = !!draft?.expense;

  useEffect(() => {
    if (!draft) return;

    // Assign supporting documents to react-hook-form field names
    const defaultSupportingDocuments: {[key: string]: string} = {};
    draft?.supporting_documents.forEach((doc, index) => {
      const fieldName = `supporting_doc_${index}`;
      defaultSupportingDocuments[fieldName] = stripImageNameFromS3URL(doc.document_image);
    });

    const initialFormState: ObjectWithSupportingDocument & FieldValues = {
      vendor: draft.vendor,
      receipt_key: stripImageNameFromS3URL(draft.receipt_image),
      description: draft.description,
      amount_of_expense: draft.amount_claimed,
      ...defaultSupportingDocuments,
    };

    formMethods.reset(initialFormState);
  }, [draft]);

  function onSubmit(form: SaveClaimFormFieldValues) {
    const {receiptKey, supportingDocumentKeys, nonDocumentFields} = extractDocumentFields(form);

    const submission: Partial<CreateStipendBody> = {
      ...nonDocumentFields,
      status: ExpenseStatus.Open,
      perk_category: nonDocumentFields?.perk_category?.id,
      allotment_id: form.allotment?.id,
      receipt_key: receiptKey,
      supporting_document_keys: supportingDocumentKeys,
      draft_id: draft?.id,
    };

    createStipend(submission).then((result) => {
      if ('error' in result) {
        if ('data' in result.error) {
          triggerCustomToast(
            'error',
            'There was a problem submitting your claim.',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.error.data?.errors.join(' '),
          );
        } else {
          triggerCustomToast('error', 'There was a problem submitting your claim');
        }
        console.error('Error submitting claim', result.error);
        return;
      }

      Analytics.sendEvent({
        action: EVENT_ACTION.SAVED_NEW_CLAIM,
        category: EVENT_CATEGORY.EMPLOYEE_MANAGED_EXPENSES,
        label: EVENT_LABEL.SAVED_PERK_CLAIM,
      });

      setSearchParams({});

      // post to slack
      if (form?.slack_message && session.data?.has_slack_token) {
        postToSlack({message: form.slack_message});
      }

      // post to MS Teams
      if (form?.ms_teams_message) {
        postToMSTeams({message: form.ms_teams_message});
      }

      triggerCustomToast(
        'success',
        'Successfully submitted your claim',
        'Your claim has been submitted for review. Once your expenses are ' +
          "reviewed and processed by your company, you'll be reimbursed directly " +
          'in an upcoming paycheck.',
        customReimbursementInfo ?? DEFAULT_REIMBURSEMENT_INFO,
      );
    });
  }

  return (
    <ComptSidePanel open={!!draftId} className="max-w-[920px] overflow-x-hidden">
      <ComptSidePanel.Header
        title="Submit a claim"
        setOpen={() => setSearchParams({})}
        headerIcon={{id: 'file-icon-blue'}}
      />
      <ComptSidePanel.Content>
        <ComptLoadingIndicator
          isLoading={
            session.isFetching ||
            allotmentQuery.isFetching ||
            companyQuery.isFetching ||
            draftExpenseQuery.isFetching ||
            draftExpenseQuery.isLoading
          }
        >
          <div className="h-full flex flex-col overflow-x-hidden">
            <form className="grow">
              <fieldset className="flex h-full grow" disabled={formMethods.formState.isSubmitting}>
                <div className="flex flex-col w-full md:flex-row">
                  <div className="flex justify-center bg-[#FAF6F1]">
                    <div
                      className={`
                      flex flex-col w-full sm:w-[480px] items-end p-6
                    `}
                    >
                      <ComptReceiptFormCarousel
                        formMethods={formMethods}
                        receiptLabel="Uploaded receipt"
                        userId={session.data?.user_id}
                        initialReceiptValue={draft?.receipt_image}
                        initialSupportingDocs={draft?.supporting_documents}
                        expenseId={draftId}
                      />
                    </div>
                  </div>
                  <div ref={contentRef} className="w-full grow-[2] py-6 px-6">
                    <StipendFormContent
                      formMethods={formMethods}
                      allotments={allotmentsWithBalance}
                      editMode={!isSubmitted}
                      userCountryCode={session.data?.country}
                    />
                    <ComputedAmountSection
                      selectedAllotmentBalance={watchedAllotment?.balance}
                      selectedAllotmentCurrency={watchedAllotment?.currency}
                      control={formMethods.control}
                    />
                    {!isSubmitted && showSlackField && (
                      <ComptTextField
                        name="slack_message"
                        label="Share a message on Slack"
                        control={formMethods.control}
                        register={formMethods.register}
                        validation={{required: false}}
                        errors={formMethods.formState.errors.slack_message}
                        placeholder={"e.g. 'I bought a...'"}
                      />
                    )}
                    {!isSubmitted && showMSTeamsField && (
                      <ComptTextField
                        name="ms_teams_message"
                        label="Share a message on Microsoft Teams"
                        control={formMethods.control}
                        register={formMethods.register}
                        validation={{required: false}}
                        errors={formMethods.formState.errors.ms_teams_message}
                        placeholder={"e.g. 'I bought a...'"}
                      />
                    )}
                    <p className="mb-400 body3 text-color-body1">
                      By submitting this claim, I certify that all this information is accurate and
                      complies with my company&apos;s perk policy. I agree that the contents of my
                      claim, including the receipt(s), are subject to review by my company&apos;s
                      designated reviewers.
                    </p>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </ComptLoadingIndicator>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        {!isSubmitted && (
          <div className="flex gap-x-2">
            <ComptButton
              onClick={formMethods.handleSubmit(onSubmit)}
              disabled={isUpdating || receiptUploading}
            >
              Submit
            </ComptButton>
            <ComptButton
              buttonType={ComptButtonType.SECONDARY}
              onClick={() => {
                setSearchParams({});
                setReceiptUploading(() => false);
              }}
              disabled={isUpdating}
            >
              Cancel
            </ComptButton>
          </div>
        )}
        {isSubmitted && (
          <ComptButton buttonType={ComptButtonType.SECONDARY} onClick={() => setSearchParams({})}>
            Close
          </ComptButton>
        )}
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
