import React, {useState} from 'react';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';
import {ReviewLnDTableController as controller} from './professional-development/components/review-lnd-table.controller';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

// Types
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

// Components
import {FilterContextProvider} from '@compt/common/compt-filter-sidebar/filter-context';
import {ReviewLnDTable} from './professional-development/components/review-lnd-table.container';
import {
  ProfessionalDevelopmentFilter,
  ReviewLndFilterSidebar,
} from './professional-development/components/review-lnd-sidebar';
import {ComptPortal, PortalRoot} from '@compt/common/compt-portal/compt-portal';
import {ReviewScoreCardCarousel} from './components/review-score-card-carousel';
import {FilterWarningBanner} from './components/filter-banner';
import {LeanComptTableContextProvider} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

// TODO: Replace in COMPT-6351
export const dummyProgramsData = [
  {
    id: 1,
    title: 'All programs',
    total_amount: 104302,
    remaining_amount: 60000,
    spent_amount: 44302,
    currency: 'USD',
  },
  {
    id: 2,
    title: 'Read, Listen, or Watch and Learn',
    total_amount: 1500,
    remaining_amount: 900,
    spent_amount: 600,
    currency: 'USD',
  },
  {
    id: 3,
    title: 'Lifelong Learning',
    total_amount: 1500,
    remaining_amount: 1000,
    spent_amount: 500,
    currency: 'USD',
  },
];

export const ReviewLndTab = () => {
  const FILTER_ID = 'review-lnd-lean-table-v1.0.1';
  const initialFilterValues = {
    [ProfessionalDevelopmentFilter.STATUS]: [],
    [ProfessionalDevelopmentFilter.SEARCH]: '',
    [ProfessionalDevelopmentFilter.DATE_SUBMITTED_START]: '',
    [ProfessionalDevelopmentFilter.DATE_SUBMITTED_END]: '',
  };

  const isMobileView = useIsMobileView();
  const [isEditingMode, setIsEditingMode] = useState(false);

  const [, setSearchParams] = useSearchParams();
  const columnDefinition = controller.getColumnDefinition(onActionMenuClicked);

  const isPDManager = useHasRequiredRole([USER_ROLES.learningDevelopmentProgramManager]);

  function onActionMenuClicked(reviewData: PreApprovalRequest, action: 'VIEW' | 'EDIT' | 'DELETE') {
    switch (action) {
      case 'VIEW':
        setSearchParams({request_id: `${reviewData.id}`});
        setIsEditingMode(false);
        break;
      case 'EDIT':
        setSearchParams({request_id: `${reviewData.id}`});
        setIsEditingMode(true);
        break;
      case 'DELETE':
        break;
    }
  }

  const shouldShowCarousel =
    !isMobileView &&
    featureEnabled(FeatureFlags.CONSOLIDATE_PROGRAMS) &&
    featureEnabled(FeatureFlags.SHOW_REVIEW_SCORECARDS) &&
    isPDManager &&
    dummyProgramsData.length > 0;

  return (
    <FilterContextProvider filterId={FILTER_ID} initialValues={initialFilterValues}>
      {shouldShowCarousel && (
        <ReviewScoreCardCarousel
          reviewsData={dummyProgramsData}
          className="mb-4"
          data-testid="program-score-cards"
        />
      )}
      <FilterWarningBanner
        filterKey={ProfessionalDevelopmentFilter.STATUS}
        shouldShowWarning={(filterValues, reviewingCount) => {
          // Statuses that need reviewer attention
          const requiredStatuses = [
            LearningRequestStatus.PRE_APPROVAL_REQUESTED,
            LearningRequestStatus.REIMBURSEMENT_REQUESTED,
            LearningRequestStatus.PARTIALLY_APPROVED,
          ];

          // Count how many of the required statuses are included in the filters
          const includedRequiredStatusCount = requiredStatuses.filter((status) =>
            filterValues.includes(status),
          ).length;

          // Show the banner if:
          // 1. There are filters selected, AND
          // 2. Some but not all required statuses are selected (1 or 2 out of 3), OR none are selected
          // 3. There are items needing attention
          return (
            filterValues.length > 0 &&
            includedRequiredStatusCount < requiredStatuses.length &&
            reviewingCount &&
            reviewingCount.learning_requests_count > 0
          );
        }}
        messageText="requests"
      />
      <LeanComptTableContextProvider columnDefinition={columnDefinition} tableId={FILTER_ID}>
        <ReviewLnDTable editingMode={isEditingMode} setEditingMode={setIsEditingMode} />
        <ComptPortal portalNode={PortalRoot.RIGHT_NAV}>
          <ReviewLndFilterSidebar />
        </ComptPortal>
      </LeanComptTableContextProvider>
    </FilterContextProvider>
  );
};
