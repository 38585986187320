import {comptApiSlice, ComptRTKTags} from './api-slice';
import {
  AccountSettingsUpdate,
  AdditionalEmail,
  AdditionalEmailListResults,
  AdditionalEmailPayload,
  PasswordChange,
  UserAssignment,
  UserSession,
  UserTag,
  VerifyAdditionalEmailRequest,
  VerifyAdditionalEmailResponse,
} from '@compt/types/account';
import {MutationTrigger} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

export type CreateTagBody = Partial<UserTag> & {
  key: string;
  value: string;
};

export type UserTagMutation = MutationTrigger<
  MutationDefinition<
    Partial<CreateTagBody>,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      NonNullable<unknown>,
      FetchBaseQueryMeta
    >,
    ComptRTKTags,
    UserTag,
    'api'
  >
>;

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updatePassword: builder.mutation<PasswordChange, PasswordChange>({
      invalidatesTags: [ComptRTKTags.USER_SESSION],
      query(body) {
        return {
          url: 'me/change_password',
          method: 'PUT',
          body,
        };
      },
    }),
    updateAccountSettings: builder.mutation<AccountSettingsUpdate, AccountSettingsUpdate>({
      invalidatesTags: [ComptRTKTags.USER_SESSION],
      query(body) {
        return {
          url: 'me/update_account',
          method: 'PUT',
          body,
        };
      },
    }),
    updateUserOnboardingInfo: builder.mutation<UserSession, UserSession>({
      invalidatesTags: [ComptRTKTags.USER_SESSION],
      query(body) {
        return {
          url: 'me/update_account',
          method: 'PUT',
          body,
        };
      },
    }),
    getAssignments: builder.query<UserAssignment[], void>({
      providesTags: [ComptRTKTags.USER_SESSION],
      query: () => ({
        url: 'me/assignments',
      }),
    }),
    updateUserTag: builder.mutation<UserTag, Partial<CreateTagBody>>({
      invalidatesTags: [ComptRTKTags.USER_SESSION],
      query: (tag: UserTag) => ({
        url: `me/tags/?key=${tag.key}&value=${tag.value}`,
        method: 'POST',
      }),
    }),
    getAdditionalEmails: builder.query<AdditionalEmailListResults, void>({
      query: () => 'me/additional_emails/',
      providesTags: [ComptRTKTags.ADDITIONAL_EMAIL_LIST],
    }),
    createAdditionalEmail: builder.mutation<AdditionalEmail, {body: AdditionalEmailPayload}>({
      query: ({body}) => ({
        url: 'me/additional_emails/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [ComptRTKTags.ADDITIONAL_EMAIL_LIST],
    }),
    resendAdditionalEmailVerification: builder.mutation<void, {body: AdditionalEmailPayload}>({
      query: ({body}) => ({
        url: `me/additional_emails/${body.id}/`,
        method: 'PUT',
        body,
      }),
      //   No invalidation necessary as there isn't a data change.
    }),
    deleteAdditionalEmail: builder.mutation<void, number>({
      query: (additional_email_id) => ({
        url: `/me/additional_emails/${additional_email_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, additional_email_id) => [
        {type: ComptRTKTags.ADDITIONAL_EMAIL, id: additional_email_id},
        ComptRTKTags.ADDITIONAL_EMAIL_LIST,
      ],
    }),
    verifyAdditionalEmail: builder.mutation<
      VerifyAdditionalEmailResponse,
      VerifyAdditionalEmailRequest
    >({
      query: (body) => ({
        url: '/me/additional_emails/verify/',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ComptRTKTags.ADDITIONAL_EMAIL_LIST],
    }),
  }),
});

export const {
  useUpdatePasswordMutation,
  useUpdateAccountSettingsMutation,
  useUpdateUserOnboardingInfoMutation,
  useGetAssignmentsQuery,
  useUpdateUserTagMutation,
  useGetAdditionalEmailsQuery,
  useCreateAdditionalEmailMutation,
  useResendAdditionalEmailVerificationMutation,
  useDeleteAdditionalEmailMutation,
  useVerifyAdditionalEmailMutation,
} = extendedComptApiSlice;
