/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, {useEffect} from 'react';

// Hooks and methods
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  RegisterOptions,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form';

// Types
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form/employee-business-expense-form.types';
import {BusinessExpenseReimbursementType} from '@compt/types/business-expenses/business-expense';

// Components
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';

interface RadioButtonProps {
  isChecked: boolean;
  label: string;
  description?: string;
  onChange: (isChecked: boolean) => void;
  disabled?: boolean;
  'data-testid'?: string;
  name?: string;
}

export const RadioButton = (props: RadioButtonProps) => (
  <label
    className={`flex items-start p-2 rounded 
    ${props.disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-surface-secondary-hover'}`}
  >
    <input
      type="radio"
      name={props.name}
      className={`h-4 w-4 rounded-full border-gray-300
          focus:ring-base-500 mr-2 mt-1
          ${
            props.disabled
              ? 'opacity-60 cursor-not-allowed bg-gray-200 text-gray-400'
              : 'cursor-pointer text-color-link'
          }`}
      checked={props.isChecked}
      disabled={props.disabled}
      onChange={(e) => !props.disabled && props.onChange(e.target.checked)}
      data-testid={props['data-testid']}
    />
    <div className="flex flex-col">
      <span className="text-sm font-medium text-gray-900">{props.label}</span>
      {props.description && <span className="text-xs text-gray-500">{props.description}</span>}
    </div>
  </label>
);

interface BEReimbursementTypeInputProps {
  selectedOption?: string | null;
  disabledOptions?: string[];
  errors?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  'data-testid'?: string;
  validation?: RegisterOptions;
  control?: Control<any>;
  register?: UseFormRegister<any>;
  name?: string;
  label?: string;
  subLabel?: string;
  formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>;
}

export const BusinessExpenseReimbursementTypeInput = ({
  selectedOption,
  disabledOptions = [],
  errors,
  'data-testid': dataTestId,
  validation,
  formMethods,
  name = 'reimbursement_type',
  label = 'Reimbursement type',
  subLabel,
}: BEReimbursementTypeInputProps) => {
  const options = [
    {
      id: BusinessExpenseReimbursementType.REIMBURSEMENT,
      label: 'Requesting reimbursement',
      description: 'The company has not yet paid you for this expense.',
    },
    {
      id: BusinessExpenseReimbursementType.REPORTING_ONLY,
      label: 'Submitting for reporting only',
      description: 'The expense was already paid by your company (i.e. through a company card).',
    },
  ];

  // Set default to `Reimbursement` if no selection yet
  useEffect(() => {
    if (!selectedOption) {
      formMethods.setValue('reimbursement_type', BusinessExpenseReimbursementType.REIMBURSEMENT, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [selectedOption, formMethods]);

  const isFormDisabled = disabledOptions.length > 0;

  const handleReimbursementTypeChange = (optionId: BusinessExpenseReimbursementType) => {
    formMethods.setValue('reimbursement_type', optionId, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const radioField = (
    <div className="rounded">
      {options.map((option) => (
        <RadioButton
          key={option.id}
          name={name}
          isChecked={
            selectedOption === option.id ||
            // TO-DO: Update in COMPT-6425
            (!selectedOption && option.id === BusinessExpenseReimbursementType.REIMBURSEMENT)
          }
          label={option.label}
          description={option.description}
          onChange={() => handleReimbursementTypeChange(option.id)}
          disabled={isFormDisabled}
          data-testid={dataTestId ? `${dataTestId}-${option.id}` : undefined}
        />
      ))}
    </div>
  );

  return (
    <ComptFormField
      field={radioField}
      label={label}
      subLabel={subLabel}
      validation={validation}
      errors={errors}
      data-testid={dataTestId}
      name={name}
      readOnly={isFormDisabled}
    />
  );
};
