import React, {useEffect, useState} from 'react';

// RTK queries
import {useUpdateUserTagMutation} from '@compt/app/services/api/accounts-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';

// Types
import {DEFAULT_REIMBURSEMENT_INFO, MAX_15_PAGE_LIMIT} from '@compt/constants';
import {USER_ROLES} from '@compt/utils/user-roles-helper';

// Hooks and methods
import {HelpScout} from '@compt/utils/analytics/vendors/help-scout';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import DOMPurify from 'dompurify';

// Components
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptModalShell} from '@compt/common/compt-modal-shell/compt-modal-shell';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {Outlet, useSearchParams} from 'react-router-dom';
import {NavLinkTab} from '@compt/common/compt-tab-button/compt-tab-nav-link-button';
import {ComptRow} from '@compt/common/compt-row/compt-row';
import {useGetDraftExpensesQuery} from '@compt/app/services/api/draft-expense-slice';
import {usePaginationQuery} from '@compt/pages/admin-pages/admin-review-programs/format-query-helper';
import {ComptWarningBanner} from '@compt/common/compt-warning-banner/compt-warning-banner';

export const ActivityPage = () => {
  const [open, setOpen] = useState(false);
  const [draftCount, setDraftCount] = useState(0);
  const isMobileView = useIsMobileView();

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const hasRequiredRole = session.data?.roles.includes(USER_ROLES.stipendEligible);

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const customReimbursementInfo = companyQuery.data?.reimbursement_info;

  const [searchParams] = useSearchParams();
  const fromStipendClaim = searchParams.get('origin') === 'claim';

  const [updateUserTag] = useUpdateUserTagMutation();

  const draftExpensesEnabled = featureEnabled(FeatureFlags.EMAIL_TO_DRAFT);

  useEffect(() => {
    if (fromStipendClaim && !featureEnabled(FeatureFlags.USER_HIDE_NDS_POST_SUBMIT_SURVEY)) {
      const helpScout = new HelpScout();
      helpScout.sendFeedback();

      updateUserTag({
        key: FeatureFlags.USER_HIDE_NDS_POST_SUBMIT_SURVEY,
        value: 'enabled',
      }).then((data) => {
        // Need to manually update the window feature
        window.features[FeatureFlags.USER_HIDE_NDS_POST_SUBMIT_SURVEY] = 'enabled';
      });
    }
  }, []);

  if (!hasRequiredRole) {
    return <Error404Page />;
  }

  const DraftExpenseNavLinkTab = () => {
    const paginationQuery = usePaginationQuery(MAX_15_PAGE_LIMIT);

    const draftExpensesQuery = useGetDraftExpensesQuery({
      filter: {
        ...paginationQuery,
        limit: paginationQuery,
      },
    });

    useEffect(() => {
      setDraftCount(draftExpensesQuery?.data?.count ?? 0);
    }, [draftExpensesQuery]);

    return (
      <NavLinkTab to="draft-expenses">
        <div className="flex justify-center gap-1 align-center">
          Drafts
          {draftCount > 0 && (
            <div
              className="flex h-6 w-6 items-center justify-center rounded-full
                  bg-stroke-critical"
            >
              <p className={`${draftCount > 99 ? 'heading6' : 'heading5'} text-color-light`}>
                {draftCount}
              </p>
            </div>
          )}
        </div>
      </NavLinkTab>
    );
  };

  return (
    <>
      <ComptModalShell
        open={open}
        setOpen={() => setOpen(false)}
        specifiedTopPadding="p-0"
        className="sm:max-w-screen-md sm:p-0"
      >
        <div className="flex justify-between pt-400 pb-300 px-400 sm:border-b">
          <div className="flex items-center">
            <div
              className="border border-brand-500 bg-brand-50 rounded-lg shadow-sm flex
            items-center p-3 mr-300"
            >
              <ComptSvgIcon
                iconName="alert-circle-blue-icon"
                svgProp={{width: '20px', height: '20px'}}
              />
            </div>
            {!isMobileView && <h2>When will I get paid?</h2>}
          </div>
          <ComptButton
            buttonType={ComptButtonType.BORDERLESS}
            iconId={ComptButtonIcon.X_STYLEABLE}
            iconClassName="stroke-gray-500"
            onClick={() => setOpen(false)}
          />
        </div>
        {isMobileView && <h2 className="px-400">When will I get paid?</h2>}
        <div className="px-400 sm:pt-100 pb-200">
          <p
            className="body2 inner-html mb-4"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(customReimbursementInfo || DEFAULT_REIMBURSEMENT_INFO),
            }}
          />
        </div>
        <div className="p-400 border-t">
          <ComptButton
            buttonType={ComptButtonType.SECONDARY}
            className="w-full"
            onClick={() => setOpen(false)}
          >
            Done
          </ComptButton>
        </div>
      </ComptModalShell>
      <ComptPage
        title="Stipend activity"
        subtitle="Stay up to date on the status of your stipend reimbursement"
        action={
          <button
            className={`flex items-center ${isMobileView ? 'mt-2 mb-6 pt-4 pb-2' : 'pr-5'}`}
            onClick={() => setOpen(true)}
          >
            <ComptSvgIcon iconName="alert-circle-question-blue-icon" className="pr-2" />
            <p className="body1 compt-link inline-flex hover:underline cursor-pointer">
              When will I get paid?
            </p>
          </button>
        }
        includeBottomHR={false}
        className="h-dvh mb-10"
      >
        {draftExpensesEnabled && draftCount ? (
          <ComptWarningBanner
            messageComponent={
              <div className="flex gap-4">
                <p className="label2">{draftCount} Receipts pending submission</p>
                <p className="body1">Confirm submission to be reimbursed for these claims.</p>
              </div>
            }
          />
        ) : (
          <></>
        )}
        <div className="flex gap-x-6 mb-6">
          <NavLinkTab to="claims-submitted">Claims submitted</NavLinkTab>
          {draftExpensesEnabled && DraftExpenseNavLinkTab()}
        </div>
        <ComptRow className="pb-10 w-full">
          <Outlet />
        </ComptRow>
      </ComptPage>
    </>
  );
};
