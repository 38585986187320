import React, {ButtonHTMLAttributes, KeyboardEventHandler} from 'react';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {twMerge} from 'tailwind-merge';

export enum ComptButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  OUTLINED = 'OUTLINED',
  OUTLINED_TRANSPARENT = 'OUTLINED_TRANSPARENT',
  DESTRUCTIVE = 'DESTRUCTIVE',
  BORDERLESS = 'BORDERLESS',
  WHITE = 'WHITE',
  GRAY = 'GRAY',
}

export enum ComptButtonSize {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export enum ComptButtonIcon {
  PLUS = 'plus-icon',
  PLUS_GRAY = 'plus-icon-gray',
  PLUS_BLUE = 'plus-icon-blue',
  GOOGLE = 'google-icon',
  X = 'x-icon',
  X_STYLEABLE = 'x-icon-styleable',
  SAVE = 'save-icon',
  ARROW_LEFT = 'arrow-left',
  CHEVRON_LEFT = 'chevron-left-icon',
  CHEVRON_RIGHT = 'chevron-right-icon',
  RED_TRASH = 'red-trash-icon',
  TRASH = 'trash-icon',
  PENCIL = 'pencil-icon',
  EYE = 'eye-icon',
  ALERT_CIRCLE_GRAY = 'alert-circle-gray-icon',
  ALERT_CIRCLE_BLACK = 'alert-circle-black-icon',
  CHEVRON_LEFT_DOUBLE = 'chevron-left-double-icon',
  CHEVRON_RIGHT_DOUBLE = 'chevron-right-double-icon',
  USER = 'user-icon',
  PASSWORD_LOCK = 'password-lock-icon',
  LOG_OUT = 'log-out-icon',
  MENU = 'menu-icon',
  MEGAPHONE_WHITE = 'megaphone-icon-white',
  DOCUMENT_BLUE = 'document-icon-blue',
}

export interface ComptButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ComptButtonType;
  disabled?: boolean;
  size?: ComptButtonSize;
  iconId?: ComptButtonIcon | string;
  trailingIcon?: boolean;
  ['data-testid']?: string;
  labelHidden?: boolean;
  ariaLabel?: string;
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement> | undefined;
}

const defaults: ComptButtonProps = {
  buttonType: ComptButtonType.PRIMARY,
  disabled: false,
  labelHidden: true,
};

const defaultClasses = [
  'compt-button',
  'rounded-[100px]',
  'px-4',
  'py-2',
  'text-xs',
  'font-semibold',
  'text-color-light',
  'shadow-sm',
  'disabled:disabled',
  'flex',
  'justify-center',
  'items-center',
  'min-w-[127px]',
];
const getClasses = (props: ComptButtonProps) => {
  const classes = [...defaultClasses];
  switch (props.buttonType) {
    case ComptButtonType.PRIMARY:
      classes.push('primary');
      break;
    case ComptButtonType.SECONDARY:
      classes.push('secondary');
      break;
    case ComptButtonType.OUTLINED:
      classes.push('outlined');
      break;
    case ComptButtonType.OUTLINED_TRANSPARENT:
      classes.push('outlined-transparent');
      break;
    case ComptButtonType.DESTRUCTIVE:
      classes.push('destructive text-color-error shadow-none');
      break;
    case ComptButtonType.BORDERLESS:
      classes.push('borderless');
      break;
    case ComptButtonType.WHITE:
      classes.push('white');
      break;
    case ComptButtonType.GRAY:
      classes.push('gray');
      break;
  }

  if (props.disabled) {
    classes.push('cursor-not-allowed');
  }

  return classes.join(' ');
};

export const ComptButton = (props: ComptButtonProps = defaults) => (
  <button
    type={props.type || 'button'}
    className={twMerge(`${getClasses(props)} ${props.className}`)}
    disabled={props.disabled}
    style={!props.children ? {paddingLeft: '8px', paddingRight: '8px'} : {}}
    onClick={props.onClick}
    data-testid={props['data-testid']}
    onKeyDown={props.onKeyDown}
  >
    {props.iconId && !props.trailingIcon && (
      <ComptSvgIcon
        iconName={props.iconId}
        className={
          (props.children ? 'pr-2 ' : ' ') + (props.iconClassName ? props.iconClassName : '')
        }
        labelHidden={props.labelHidden}
        ariaLabel={props.ariaLabel}
      />
    )}
    <p
      className={twMerge(`
        ${props.size === ComptButtonSize.LARGE ? 'body2' : 'body3'}
        compt-text--semi-bold
        text-current
        ${props.disabled ? 'cursor-not-allowed' : ''}
        ${props.textClassName}
      `)}
    >
      {props.children}
    </p>
    {props.iconId && props.trailingIcon && (
      <ComptSvgIcon
        iconName={props.iconId}
        className={
          (props.children ? 'pl-2 ' : ' ') + (props.iconClassName ? props.iconClassName : '')
        }
        labelHidden={props.labelHidden}
        ariaLabel={props.ariaLabel}
      />
    )}
  </button>
);

ComptButton.defaultProps = defaults;
